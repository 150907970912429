import React, { useEffect, useState } from 'react';

import { Text } from '../LandingPage';

const StatsWithIcon = ({ icon, stat, description }) => {
  const [statValue, setStatValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (statValue < parseFloat(stat)) {
        setStatValue(statValue + parseFloat(stat) / 10);
      }
      if (statValue === 118.79999999999998) {
        setStatValue(108);
      }
    }, 30);

    return () => clearInterval(interval);
  }, [stat, statValue]);

  return (
    <div className='flex flex-row p-3'>
      <div className='mr-4 min-h-[1.5rem] min-w-[1.5rem]'>
        <img src={icon} alt='Icon' />
      </div>
      <div className='flex flex-col -mt-2'>
        <Text className={'text-white sm:text-regular text-base'}>
          {statValue === 2.5 ? statValue.toFixed(1) : statValue.toFixed(0)}
          <span
            className={statValue === 2.5 || statValue === 108 ? 'hidden' : ''}
          >
            +
          </span>
          <span className={statValue === 2.5 ? '' : 'hidden'}>m</span>
        </Text>
        <Text className={'text-white sm:text-base text-sm'}>{description}</Text>
      </div>
    </div>
  );
};

export default StatsWithIcon;
