import React, { useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import DateFnsUtils from '@date-io/date-fns';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { COMMON } from 'src/Constants';

import { AppContext } from '../Contexts';

import YearSelector from './OnBoarding/YearSelector';
import Button from './Button';

const useStyles = makeStyles(() => ({
  container: {
    flexGrow: 1,
  },
  textCaseTransform: {
    textTransform: 'uppercase',
  },
}));

const ExperienceForm = (props) => {
  const classes = useStyles();

  const context = useContext(AppContext);
  const [isCurrent, setCurrent] = useState(props.isCurrent);
  const [designation, setDesignation] = useState(props.designation);
  const [company, setCompany] = useState(props.company);
  const [startYear, setStartYear] = useState(
    props.startUTC !== null ? props.startUTC : props.startYear,
  );
  const [endYear, setEndYear] = useState(
    props.endUTC !== null ? props.endUTC : props.endYear,
  );
  const [location, setLocation] = useState(props.locationText);

  const onClick = async () => {
    if (
      designation &&
      company &&
      startYear &&
      (endYear || isCurrent) &&
      location
    ) {
      await props.onSubmitted({
        id: props.id,
        designation,
        company,
        startYear: new Date(startYear).getFullYear(),
        endYear: isCurrent !== true ? new Date(endYear).getFullYear() : null,
        startUTC: new Date(startYear).toUTCString(),
        endUTC: isCurrent !== true ? new Date(endYear).toUTCString() : null,
        start_month: new Date(startYear).getMonth(),
        end_month: isCurrent !== true ? new Date(endYear).getMonth() : null,
        location,
        isCurrent,
      });
    } else {
      context.showError(COMMON.REQUIRED_ERROR_MSG);
    }
  };

  return (
    <div className={classes.container}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <FormGroup>
          <Grid spacing={2} container>
            <Grid xs={12} sm={6} item>
              <TextField
                autoFocus={true}
                fullWidth={true}
                value={designation}
                onChange={(event) => setDesignation(event.target.value)}
                label='Designation*'
                variant='outlined'
                margin='dense'
                notched={false}
                labelWidth={200}
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <TextField
                fullWidth={true}
                value={company}
                onChange={(event) => setCompany(event.target.value)}
                label='Company*'
                variant='outlined'
                margin='dense'
                notched={false}
                labelWidth={200}
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <YearSelector
                value={startYear ? new Date(startYear) : null}
                placeholder='Start month / year*'
                onChange={(value) => {
                  setStartYear(value);
                }}
                isExperience={true}
              />
            </Grid>
            <Grid xs={12} sm={6} item>
              <YearSelector
                value={endYear ? new Date(endYear) : null}
                placeholder='End month / year*'
                minDate={new Date(startYear)}
                disabled={isCurrent}
                onChange={(value) => {
                  setEndYear(value);
                }}
                isExperience={true}
              />
            </Grid>
            <Grid xs={12} item>
              <TextField
                fullWidth={true}
                value={location}
                onChange={(event) => setLocation(event.target.value)}
                label='Location*'
                variant='outlined'
                margin='dense'
                notched={false}
                labelWidth={200}
              />
            </Grid>
            <Grid xs={12} item>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={isCurrent}
                    onChange={() => {
                      setCurrent(!isCurrent);
                      setEndYear(null);
                    }}
                    value={'current'}
                  />
                }
                label='I am currently working here'
              />
            </Grid>
            <Grid xs={12} direction='row' justify='flex-end' item container>
              <Button
                onClick={onClick}
                title={props.id === -1 ? 'Save' : 'Update'}
              />
            </Grid>
          </Grid>
        </FormGroup>
      </MuiPickersUtilsProvider>
    </div>
  );
};

ExperienceForm.propTypes = {
  isCurrent: PropTypes.bool,
  id: PropTypes.number,
  designation: PropTypes.string,
  company: PropTypes.string,
  startYear: PropTypes.string,
  startUTC: PropTypes.any,
  endYear: PropTypes.string,
  endUTC: PropTypes.any,
  locationText: PropTypes.string,
  onSubmitted: PropTypes.func,
};

ExperienceForm.defaultProps = {
  isCurrent: false,
  id: -1,
  designation: '',
  company: '',
  startYear: null,
  startUTC: null,
  endYear: null,
  endUTC: null,
  locationText: '',
  onSubmitted: () => {},
};

export default withRouter(ExperienceForm);
