import { useHistory } from 'react-router-dom';

import Button from 'src/Components/Button';
import Routes from 'src/Utils/Routes';

export const BlogCard = ({ blog }) => {
  const { attributes } = blog || {};
  const history = useHistory();

  return (
    <div className='mx-auto w-full'>
      <div className='bg-white shadow-md border border-gray-200 rounded-lg mb-5'>
        <img
          className='rounded-t-lg w-full h-[250px]'
          src={`${process.env.REACT_APP_STRAPI_API}${
            attributes?.image?.data?.attributes?.url ?? ''
          }`}
          alt={attributes?.name ?? ''}
        />
        <div
          className='p-5 font-[karla-regular]'
          title={attributes?.name ?? ''}
        >
          <h5 className='text-gray-900 font-bold text-xl tracking-tight mb-2 truncate overflow-ellipsis'>
            {attributes?.name ?? ''}
          </h5>

          <div className='line-clamp-2 text-gray-500 h-11'>
            {attributes?.description ?? ''}
          </div>

          <Button
            title='Read more'
            onClick={() =>
              history.push(`${Routes.Blogs}/${attributes?.slug ?? ''}`)
            }
            className='!px-4 py-2'
          />
        </div>
      </div>
    </div>
  );
};
