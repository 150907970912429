const FeaturesTopLeft = () => {
  const array = [
    'Personalized Job Suggestion Test: SkillesScore examines your unique abilities, qualifications, and professional objectives to offer job recommendations that align with your aims.',
    'Skill Gap Analysis: Make sure you are ready for the roles you are interested in by identifying any skill gaps and receiving job recommendations that can help you close those gaps.',
    "Optimized Job Search: To save time and effort and improve your chances of discovering the perfect career opportunity, use SKilledScore's best career aptitude test to accelerate your job search.",
  ];

  return array.map((data) => {
    return (
      <div key={data}>
        <ul>
          <li className='list-disc ml-5 mt-8 text-base md:mr-20'>{data}</li>
        </ul>
      </div>
    );
  });
};
export default FeaturesTopLeft;
