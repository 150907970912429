import React, { useContext, useEffect, useState } from 'react';
import array from 'lodash/array';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

import { COMMON } from 'src/Constants';
import { AppContext } from 'src/Contexts';

import Button from './Button';
import ChipsInputForm from './ChipsInputForm';
import Dialog from './Dialog';

const SkillsInputDialog = (props) => {
  const handleDelete = (id, data, setter) => {
    setter(data.filter((item) => item.id !== id));
  };

  const [softSkillsState, setSoftSkillsState] = useState(props.softSkills);
  const [hardSkillsState, setHardSkillsState] = useState(props.hardSkills);
  const [, setError] = useState(false);

  const context = useContext(AppContext);

  useEffect(() => {
    setSoftSkillsState(props.softSkills);
    setHardSkillsState(props.hardSkills);
  }, [props.softSkills, props.hardSkills]);

  const addNewSkill = (item, rate, data, setter) => {
    if (array.findIndex(data, ['id', item.id]) !== -1) {
      return;
    }
    setter([...data, { ...item, rate }]);
    setError(false);
  };

  const onSubmit = async () => {
    if (softSkillsState.length > 0 || hardSkillsState.length > 0) {
      return await props.onSubmitted({
        softSkills: softSkillsState,
        hardSkills: hardSkillsState,
      });
    }
    context.showError(COMMON.REQUIRED_ERROR_MSG);
  };

  return (
    <Dialog title='Add Your Skills' open={props.open} onClose={props.onClose}>
      <Grid direction='row' container>
        <Grid item sm={6} xs={12}>
          <ChipsInputForm
            onNewItem={(item, rate) => {
              addNewSkill(item, rate, softSkillsState, setSoftSkillsState);
            }}
            type='s'
            profession_id={props.profession_id}
            data={softSkillsState}
            onDeleteItem={(id) =>
              handleDelete(id, softSkillsState, setSoftSkillsState)
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ChipsInputForm
            onNewItem={(item, rate) => {
              addNewSkill(item, rate, hardSkillsState, setHardSkillsState);
            }}
            type='h'
            profession_id={props.profession_id}
            data={hardSkillsState}
            onDeleteItem={(id) =>
              handleDelete(id, hardSkillsState, setHardSkillsState)
            }
          />
        </Grid>
        <Grid
          style={{ marginTop: '1%' }}
          xs={12}
          direction='row'
          justify='flex-end'
          item
          container
        >
          <Button onClick={onSubmit} title='Save' />
        </Grid>
      </Grid>
    </Dialog>
  );
};

SkillsInputDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  softSkills: PropTypes.array,
  hardSkills: PropTypes.array,
  onSubmitted: PropTypes.func,
  profession_id: PropTypes.string,
};

SkillsInputDialog.defaultProps = {
  softSkills: [],
  hardSkills: [],
};

export default SkillsInputDialog;
