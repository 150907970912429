/* eslint-disable simple-import-sort/imports */
import React from 'react';
import Accordion from 'src/Components/LandingPage/Accordian';
import { IoIosArrowDown } from 'react-icons/io';

const JobAccordion = ({ job }) => {
  const { title, description, responsibilities, requirements } = job;

  return (
    <Accordion
      title={title}
      description={description}
      containerStyle='custom-container-style'
      icon={<IoIosArrowDown />}
      titleStyle='custom-title-style'
    >
      <div className='overflow-y-scroll h-[90vh]'>
        <p className='text-DarkBluePurple pb-1 pt-2 font-semibold '>
          Responsibilities:
        </p>
        <ul className='list-disc ml-5'>
          {responsibilities.map((responsibility, index) => (
            <li
              className='text-xs md:text-sm leading-5 md:leading-6'
              key={index}
            >
              {responsibility}
            </li>
          ))}
        </ul>
        <p className='text-DarkBluePurple pb-1 pt-2 font-semibold'>
          Requirements:
        </p>
        <ul className='list-disc ml-5'>
          {requirements.map((responsibility, index) => (
            <li
              className='text-xs md:text-sm leading-5 md:leading-6'
              key={index}
            >
              {responsibility}
            </li>
          ))}
        </ul>
        <p className='text-DarkBluePurple pb-1 pt-2 font-semibold'>Contact:</p>{' '}
        <p className='text-xs md:text-sm leading-5 md:leading-6'>
          {' '}
          Interested candidates are invited to submit their resume and a
          portfolio of relevant projects to{' '}
          <a
            className=' font-medium text-DarkBluePurple underline'
            href='mailto:hr@skilledscore.com'
          >
            hr@skilledscore.com
          </a>
        </p>
      </div>
    </Accordion>
  );
};

export default JobAccordion;
