/* eslint-disable simple-import-sort/imports */
import PropTypes from 'prop-types';
import React from 'react';
import {
  Dialog as MDialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    overflow: 'auto !important',
  },
  dialogTitle: {
    marginTop: '4%',
  },
  closeButton: {
    color: '#000',
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
    height: '3rem',
    width: '3rem',
  },
}));

const Dialog = (props) => {
  const classes = useStyles();

  return (
    <MDialog
      fullWidth={true}
      open={props.open}
      onClose={props.onClose}
      aria-labelledby='form-dialog-title'
      BackdropProps={{ style: { opacity: '0.5' } }}
      PaperProps={{
        style: { minWidth: '45vw' },
      }}
    >
      <DialogTitle className={classes.dialogTitle} id='form-dialog-title'>
        <Grid container direction={'row'}>
          <Grid
            className={`text-primaryBg text-2xl font-bold w-[90%]  ${props?.titleStyle}`}
          >
            {props.iconUrl ? (
              <img src={props.iconUrl} className='w-40' alt='icon' />
            ) : (
              props.title
            )}
          </Grid>
          <Grid item>
            {props?.isProfileClose ? (
              <button
                onClick={props.onClose}
                className='rounded-2xl text-sm whitespace-nowrap border-2 px-4 h-8 border-DarkBluePurple text-DarkBluePurple absolute right-7 top-5'
              >
                Close
              </button>
            ) : (
              <IconButton
                aria-label='close'
                className={classes.closeButton}
                onClick={props.onClose}
              >
                <div
                  className={`${
                    props.loader ? 'cursor-not-allowed' : 'cursor-pointer'
                  }`}
                >
                  <CloseIcon />
                </div>
              </IconButton>
            )}
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        {props.children}
      </DialogContent>
      <Grid xs={12} item>
        <Grid
          direction='column'
          justifyContent='flex-end'
          alignItems='flex-end'
          alignContent='flex-end'
          container
          className={`px-7 py-4 ${props.bottonButtonStyle}`}
        >
          {props.dashboard ? (
            <button
              onClick={props.onClose}
              className='bg-primaryBg shadow-md shadow-gray-400 text-white px-8 mt-5 p-3 rounded-lg'
            >
              Close
            </button>
          ) : null}
        </Grid>
      </Grid>
    </MDialog>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  children: PropTypes.node,
  iconUrl: PropTypes.string,
  isProfileClose: PropTypes.bool,
  titleStyle: PropTypes.string,
  bottonButtonStyle: PropTypes.string,
  dashboard: PropTypes.bool,
  loader: PropTypes.bool,
};

Dialog.defaultProps = {
  height: 'auto',
  width: 'auto',
  children: null,
  iconUrl: '',
  isProfileClose: false,
  titleStyle: '',
  bottonButtonStyle: '',
  dashboard: false,
  loader: false,
};

export default Dialog;
