import Api from './index';
import Response from './Response';

const { next } = Response;

const Misc = {
  listCountries: async (token) => {
    const endpoint = '/misc/country';

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      return await Api.get(endpoint, config);
    } catch (e) {
      next(e.response);
    }
  },

  listCities: async (
    country_id = '',
    config,
    { query = '', last = null, size = 50 },
  ) => {
    const endpoint = `/misc/country/${country_id}/city?name=${query}&size=${size}${
      last ? `&last=${last}` : ''
    }`;

    try {
      return await Api.get(endpoint, config);
    } catch (e) {
      next(e.response);
    }
  },
};

export default Misc;
