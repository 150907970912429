/* eslint-disable max-lines */
/* eslint-disable simple-import-sort/imports */
import { useState } from 'react';
import { Header } from 'src/Components/LandingPage';
import Footer from 'src/Components/LandingPage/Footer';
import ReCAPTCHA from 'react-google-recaptcha';
import Feedback from 'src/Api/Feedback';
import { FaCheck } from 'react-icons/fa';
import { scrollSmoothTo } from 'src/Utils/utils';

const BecomeAPartner = () => {
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  // Form error states
  const [fullNameError, setFullNameError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [captchaError, setCaptchaError] = useState('');

  const handleChange = (e, setState) => {
    const { name, value } = e.target;
    setState(value);

    switch (name) {
      case 'email':
        setEmailError('');
        break;
      case 'fullName':
        setFullNameError('');
        break;
      case 'message':
        setMessageError('');
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    let valid = true;

    if (!fullName) {
      setFullNameError('Full name is required');
      valid = false;
    }

    if (!email) {
      setEmailError('Email is required');
      valid = false;
    }

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isValidEmail = emailPattern.test(email);
    if (email && !isValidEmail) {
      setEmailError('Please enter a valid email address.');
      valid = false;
    }

    if (!message) {
      setMessageError('Message is required');
      valid = false;
    }

    if (!captcha) {
      setCaptchaError('Please verify that you are not a robot');
      valid = false;
    }

    return valid;
  };

  const submitForm = async () => {
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }

    setLoading(true);
    setResponseMessage('');

    try {
      // Prepare the form data to send to the API
      const formData = {
        fullName,
        email,
        phone,
        message,
        token: captcha,
      };

      // Call the API to submit the form
      const res = await Feedback.becomePartner(formData);

      if (res) {
        setResponseMessage('Your request has been submitted successfully');
        scrollSmoothTo('heading');
      } else {
        setResponseMessage('Failed to submit the request.');
      }

      // Clear form fields after submission
      setFullName('');
      setEmail('');
      setPhone('');
      setMessage('');
      setCaptcha('');
    } catch (error) {
      setResponseMessage('An error occurred while submitting the form.');
    } finally {
      setLoading(false);
    }
  };

  const handleMessageChange = (e) => {
    const maxLines = 5;
    const lines = e.target.value.split('\n').length;

    if (lines <= maxLines) {
      setMessage(e.target.value);

      // Clear message error when input is valid
      if (e.target.value) {
        setMessageError('');
      }
    }
  };

  return (
    <div>
      <div className='bg-primaryBg px-2 '>
        <div className='max-w-screen-2xl mx-auto'>
          <div className='mx-2 lg:px-8'>
            <Header />
          </div>
        </div>
      </div>

      <div className='container w-full mx-auto my-16 px-6 md:px-12 lg:px-20 '>
        <h1
          id='heading'
          className='!text-3xl md:!text-5xl font-medium text-center text-DarkBluePurple py-5 !font-[IBM-Ple-Sans]'
        >
          Become a Partner
        </h1>
        <p className='text-center'>
          Are you interested in collaborating with us? We welcome partnerships
          that create mutual value and drive innovation. Let’s work together to
          achieve remarkable success!
        </p>
        {/* Response Message */}
        {responseMessage && (
          <div className='text-center mt-4'>
            <p
              className={
                responseMessage.includes('successfully')
                  ? ' text-DarkBluePurple font-semibold flex items-center justify-center'
                  : 'hidden'
              }
            >
              {responseMessage}{' '}
              <span className='ml-1'>
                <FaCheck className=' text-DarkBluePurple' />
              </span>
            </p>
          </div>
        )}
      </div>

      <div className='text-white gradient-button lg:h-[600px] mx-5 md:mx-20 lg:mx-40 mb-10 md:mb-20 text-center'>
        <div className='max-w-screen-2xl mx-auto flex justify-center items-center h-full'>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 items-start px-4 md:px-10 lg:px-16 w-full py-4'>
            <div className='w-full h-full'>
              <h2 className='text-2xl md:text-5xl font-[IBM-Ple-Sans] text-left py-2 md:py-5'>
                Get in Touch
              </h2>
              <p className='text-lg md:text-2xl text-left hidden lg:block'>
                Fill out the form, and we’ll get back <br /> to you shortly.
              </p>
              <p className='text-lg md:text-2xl text-left block lg:hidden'>
                Fill out the form, and we’ll get back to you shortly.
              </p>
            </div>

            <div className='w-full'>
              <div className='flex flex-col items-center space-y-5 w-full'>
                {/* Full Name */}
                <div className='w-full'>
                  <input
                    name='fullName'
                    value={fullName}
                    onChange={(e) => handleChange(e, setFullName)}
                    type='text'
                    placeholder='Enter your full name'
                    className='text-black w-full h-14 rounded-lg px-4'
                  />
                  {fullNameError && (
                    <p className='text-red-500 text-left'>{fullNameError}</p>
                  )}
                </div>

                {/* Email */}
                <div className='w-full'>
                  <input
                    name='email'
                    value={email}
                    onChange={(e) => handleChange(e, setEmail)}
                    type='text'
                    placeholder='Enter your email'
                    className='text-black w-full h-14 rounded-lg px-4'
                  />
                  {emailError && (
                    <p className='text-red-500 text-left'>{emailError}</p>
                  )}
                </div>

                {/* Phone (Optional) */}
                <div className='w-full'>
                  <input
                    value={phone}
                    onChange={(e) => handleChange(e, setPhone)}
                    type='text'
                    placeholder='Enter your phone number (Optional)'
                    className='text-black w-full h-14 rounded-lg px-4'
                  />
                </div>

                {/* Message */}
                <div className='w-full'>
                  <textarea
                    name='message'
                    value={message}
                    maxLength={400}
                    draggable={false}
                    onChange={handleMessageChange}
                    placeholder="Tell us how you'd like to partner with us and any other details."
                    className='text-black w-full h-32 rounded-lg px-4'
                    style={{ resize: 'none' }}
                  />
                  {messageError && (
                    <p className='text-red-500 text-left'>{messageError}</p>
                  )}
                </div>

                {/* reCAPTCHA */}
                <div className='w-full flex flex-col xl:flex-row justify-between items-center'>
                  <div className='mb-3 xl:mb-0  w-full'>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA || ''}
                      onChange={(value) => {
                        setCaptcha(value);
                        setCaptchaError('');
                      }}
                    />
                    <div>
                      {captchaError && (
                        <p className='text-red-500 text-left'>{captchaError}</p>
                      )}
                    </div>
                  </div>
                  <div className='flex justify-end w-full'>
                    <button
                      disabled={loading}
                      onClick={submitForm}
                      className='bg-black flex justify-center text-lg font-[Karla-Regular] items-center text-white h-14 w-40 md:w-36 rounded-lg'
                    >
                      {loading ? 'Submitting...' : 'Submit'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default BecomeAPartner;
