import { formatDistanceToNow, isToday, parseISO } from 'date-fns';
import { driver } from 'driver.js';
import moment from 'moment';

import {
  Breakdown,
  Breakdown2,
  CareerSummary,
  CareerSummary2,
  Courses,
  Courses2,
  Jobs,
  SkillsFactors,
  SkillsFactors2,
  TopCountries,
} from 'src/Assets/images/dashboard/index';
import {
  COMMON,
  ILO_CHART_WIDTH_MAPPER,
  PAYMENT_STATUS,
  PREMIUM_PLAN,
} from 'src/Constants/Common';
import { FirebaseAnalyticsEvent } from 'src/Firebase/analytics';

// Function to check if the screen is small
const isSmallScreen = () => window.innerWidth <= 768;

// Function to get the appropriate image based on screen size
const getBreakdownImage = () => (isSmallScreen() ? Breakdown2 : Breakdown);

// Function to update the image based on screen size
const updateBreakdownImage = () => {
  const imgElement = document.getElementById('appended-image');
  if (imgElement) {
    imgElement.src = getBreakdownImage();
  }
};

// Function to update the image based on screen size
const getCoursesImage = () => (isSmallScreen() ? Courses2 : Courses);

// Function to update the image based on screen size
const updateCoursesImage = () => {
  const imgElement = document.getElementById('appended-image6');
  if (imgElement) {
    imgElement.src = getCoursesImage();
  }
};

// Function to update the image based on screen size
const getCareerSummaryImage = () =>
  isSmallScreen() ? CareerSummary2 : CareerSummary;

// Function to update the image based on screen size
const updateCareerSummaryImage = () => {
  const imgElement = document.getElementById('appended-image1');
  if (imgElement) {
    imgElement.src = getCareerSummaryImage();
  }
};
// Function to update the image based on screen size
// const getCareerPathImage = () => (isSmallScreen() ? CareerPath2 : CareerPath);

// Function to update the image based on screen size
// const updateCareerPathImage = () => {
//   const imgElement = document.getElementById('appended-image2');
//   if (imgElement) {
//     imgElement.src = getCareerPathImage();
//   }
// };

const getSkillFactorsImage = () =>
  isSmallScreen() ? SkillsFactors2 : SkillsFactors;

const updateSkillsFactorImage = () => {
  const imgElement = document.getElementById('appended-image4');
  if (imgElement) {
    imgElement.src = getSkillFactorsImage();
  }
};
// Add event listener to update image on window resize
window.addEventListener('resize', updateSkillsFactorImage);
// Initial image update on page load
document.addEventListener('DOMContentLoaded', updateSkillsFactorImage);
// Add event listener to update image on window resize
window.addEventListener('resize', updateBreakdownImage);
// Initial image update on page load
document.addEventListener('DOMContentLoaded', updateBreakdownImage);

// Add event listener to update image on window resize
window.addEventListener('resize', updateCoursesImage);
// Initial image update on page load
document.addEventListener('DOMContentLoaded', updateCoursesImage);

// Add event listener to update image on window resize
window.addEventListener('resize', updateCareerSummaryImage);
// Initial image update on page load
document.addEventListener('DOMContentLoaded', updateCareerSummaryImage);
// Add event listener to update image on window resize
// window.addEventListener('resize', updateCareerPathImage);
// // Initial image update on page load
// document.addEventListener('DOMContentLoaded', updateCareerPathImage);

export const mapProjects = (data) => {
  return data?.map((item) => ({
    title: `${item.title}`,
    subtitle: `${item.company_name}`,
    date: `${item.start_year} - ${item.end_year}`,
    startYear: item.start_year,
    startUTC: item.start_year_utc,
    endYear: item.end_year,
    endUTC: item.end_year_utc,
    ...item,
    image: item.image_url,
    resume: item.cv,
  }));
};

export const mapEducation = (data) => {
  return data.map((item) => {
    const title = `${item.degree_name ?? ''}
    ${item.field_name ? `, ${item.field_name}` : ''}
    ${item.grade ? `, ${item.grade}` : ''}`;
    return {
      ...item,
      locationText: item.location,
      title: title,
      subtitle: `${item.university_name} ${
        item.university_name && item.location ? ',' : ''
      } ${item.location}`,
      startYear: item.start_year,
      startUTC: item.start_year_utc,
      endYear: item.end_year,
      endUTC: item.end_year_utc,
      degree: { id: item.degree_id, title: item.degree_name },
      fieldOfStudy: { id: item.study_field_id, title: item.field_name },
      institute: item.university_name,
    };
  });
};

export const mapExperience = (data) => {
  return data.map((item) => {
    const nonEmptySubtitleStrings = [item.company, item.location].filter(
      (str) => str !== null && str.trim() !== '',
    );

    return {
      title: item.designation,
      subtitle: nonEmptySubtitleStrings.join(', '),
      locationText: item.location,
      startYear: item.start_year,
      startUTC: item.start_year_utc,
      endYear: item.is_working ? null : item.end_year,
      endUTC: item.end_year_utc,
      ...item,
    };
  });
};

export const formatDate = (date) => {
  return moment(date * 1000).format('MMMM D, YYYY');
};

export const findPlanByPaymentId = (paymentId) => {
  return PREMIUM_PLAN.find((plan) => plan.priceId === paymentId);
};

export const filterJobsByQuery = (jobs, query) => {
  query = query?.toLowerCase();
  return jobs?.filter(
    (job) =>
      job?.jobTitle?.toLowerCase()?.includes(query) ||
      job?.companyName?.toLowerCase()?.includes(query) ||
      job?.location?.toLowerCase()?.includes(query),
  );
};
export const filterUpskillsByQuery = (upskills, query) => {
  query = query?.toLowerCase();
  return upskills?.filter((upskill) =>
    upskill?.title?.toLowerCase()?.includes(query),
  );
};

export const sortJobsByDate = (jobs) =>
  jobs?.sort((a, b) => (moment(a.jobPosting).isBefore(b.jobPosting) ? 1 : -1));

export const getEmptyCardText = (
  data,
  filteredData,
  searchQuery,
  favouriteMessage,
) => {
  if ((data && !filteredData.length && !searchQuery) || !data) {
    return favouriteMessage;
  }
  return 'No Result Found';
};

export const getILOScore = (ilo) => {
  if (!ilo) return 0;
  return ((10 - ilo) / 9) * 100;
};

export const getPercentage = (val, percentage) => Math.round(val * percentage);
export const capitalize = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};
export const truncateString = (inputString, prefixLength, suffixLength) => {
  if (inputString.length <= prefixLength + suffixLength) {
    return inputString;
  }

  const prefix = inputString.slice(0, prefixLength);
  const suffix = inputString.slice(-suffixLength);
  const ellipsis = '.....';

  return `${prefix}${ellipsis}${suffix}`;
};

export const formatJobDate = (time) => {
  const parsedDate = parseISO(time);
  const formattedDate = formatDistanceToNow(parsedDate);
  const isTodayDate = isToday(parsedDate);
  if (isTodayDate) {
    return 'Posted today';
  }
  return `${formattedDate} ago`;
};

export const getLocalUser = async () => {
  const userData = localStorage.getItem('loginData');
  return await JSON.parse(userData);
};

export const getIloChartStyle = (userIlo, currentIlo, isPremium) => {
  let backgroundColor = '#222B6D';
  let color = 'white';

  if (userIlo > currentIlo || !userIlo) {
    backgroundColor = '#eeeeee';
    color = isPremium ? 'black' : 'white';
  }
  let style = {
    color,
    fontSize: 7,
    marginTop: 2,
    backgroundColor,
    borderTopRightRadius: 50,
    borderTopLeftRadius: 50,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    fontWeight: 'bold',
    width: ILO_CHART_WIDTH_MAPPER[currentIlo],
  };
  if (currentIlo === 1) {
    style = {
      ...style,
      height: 0,
      width: 16,
      borderLeft: '5px solid white',
      borderRight: '5px solid white',
      borderBottom: `10px solid ${backgroundColor}`,
    };
  }
  return style;
};

export const customSort = (dataArray, query) => {
  return dataArray?.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    const exactMatchA = nameA === query.toLowerCase();
    const exactMatchB = nameB === query.toLowerCase();

    if (exactMatchA && !exactMatchB) {
      return -1;
    } else if (!exactMatchA && exactMatchB) {
      return 1;
    } else if (exactMatchA && exactMatchB) {
      return 0;
    } else if (
      nameA.startsWith(query?.toLowerCase()) &&
      !nameB.startsWith(query?.toLowerCase())
    ) {
      return -1;
    } else if (
      !nameA.startsWith(query?.toLowerCase()) &&
      nameB.startsWith(query?.toLowerCase())
    ) {
      return 1;
    }
    return nameA.localeCompare(nameB);
  });
};

export const scrollSmoothTo = (elementId, page, history) => {
  if (page && history && location.pathname !== page) {
    history.push(page);
    setTimeout(() => {
      scrollSmoothTo(elementId);
    });
  } else {
    const element = document.getElementById(elementId);
    if (element) {
      element?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }
};

export const createCaseInsensitiveRegex = (text) =>
  new RegExp(text?.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');

function appendImageToElement(elementId, imageId, imageUrl) {
  const ele = document.getElementById(elementId);

  if (ele && ele.innerHTML) {
    const img = document.createElement('img');
    img.src = imageUrl;
    img.style.position = 'absolute';
    img.style.width = '100%';
    img.style.height = '100%';
    img.style.top = '0';
    img.style.left = '0';
    img.id = imageId;
    ele.appendChild(img);
  }
}

const createStep = (
  element,
  title,
  description,
  isPremium,
  side,
  align,
  imageSrc,
  upgradeMessage,
) => {
  return {
    element,
    popover: {
      title,
      description: `${
        imageSrc
          ? `<img src=${imageSrc} style='height: 100%; width: 100%;' />`
          : ''
      }<span style='font-size: 15px; display: block; margin-top: 10px;'>${description} ${
        !isPremium ? upgradeMessage : ''
      }</span>`,
      side,
      align,
      showButtons: ['next', 'close', 'previous'],
    },
  };
};

export const facebookEvent = (eventName, params) => {
  if (process.env.REACT_APP_NODE_ENV === 'Production') {
    // eslint-disable-next-line no-undef
    fbq('track', eventName, params || '');
  }
};

export const walkthroughSteps = (isPremium, setIsWalkthroughRunning) => {
  const steps = [
    {
      element: '#score-chart',
      popover: {
        title: 'Skilled Score',
        description:
          'Your Skilled Score reflects your overall skills and capabilities.',
        side: 'top',
        align: 'start',
        showButtons: ['next', 'close'],
        onNextClick: () => {
          appendImageToElement(
            'breakdown-scores',
            'appended-image',
            getBreakdownImage(),
          );
          driverObj.moveNext();
        },
      },
    },
    {
      element: '#breakdown-scores',
      popover: {
        title: 'Breakdown of Score',
        description: `Get to know about the breakdown of your calculated score. ${
          !isPremium
            ? 'Upgrade your plan to view the breakdown of score.'
            : COMMON.DUMMY_TEXT
        } `,
        side: 'top',
        align: 'start',
        showButtons: ['next', 'close', 'previous'],
        onNextClick: () => {
          appendImageToElement(
            'career-summary',
            'appended-image1',
            getCareerSummaryImage(),
          );
          driverObj.moveNext();
        },
      },
    },

    {
      element: '#career-summary',
      popover: {
        title: 'Career Summary',
        description: `This is your career summary, a snapshot of your professional life. ${
          !isPremium
            ? 'Upgrade your plan to view career summary.'
            : COMMON.DUMMY_TEXT
        } `,
        side: 'top',
        align: 'start',
        showButtons: ['next', 'close', 'previous'],
        onNextClick: () => {
          appendImageToElement(
            'top-destination',
            'appended-image3',
            TopCountries,
          );
          driverObj.moveNext();
        },
      },
    },
    // {
    //   element: '#career-path',
    //   popover: {
    //     title: 'Career Path',
    //     description: `Uncover your career path and plan for the future. ${
    //       !isPremium
    //         ? 'Upgrade your plan to view career path.'
    //         : COMMON.DUMMY_TEXT
    //     } `,
    //     side: 'top',
    //     align: 'start',
    //     showButtons: ['next', 'close', 'previous'],
    //     onNextClick: () => {
    //       appendImageToElement(
    //         'top-destination',
    //         'appended-image3',
    //         TopCountries,
    //       );
    //       driverObj.moveNext();
    //       localStorage.setItem('walkthroughSeen', 'true');
    //     },
    //   },
    // },
    {
      element: '#top-destination',
      popover: {
        title: 'Top Destinations',
        description: `Discover the top countries to relocate  to with the most job opportunities . ${
          !isPremium
            ? 'Upgrade your plan to view top destination.'
            : COMMON.DUMMY_TEXT
        } `,
        side: 'top',
        align: 'start',
        showButtons: ['next', 'close', 'previous'],
        onNextClick: () => {
          appendImageToElement(
            'skill-factors',
            'appended-image4',
            getSkillFactorsImage(),
          );
          driverObj.moveNext();
          localStorage.setItem('walkthroughSeen', 'true');
        },
      },
    },
    {
      element: '#skill-factors',
      popover: {
        title: 'Skill Factors',
        description: `Uncover how your score is calculated based on these factors. ${
          !isPremium
            ? 'Upgrade your plan to view skill factors.'
            : COMMON.DUMMY_TEXT
        } `,
        side: 'top',
        align: 'start',
        showButtons: ['next', 'close', 'previous'],
        onNextClick: () => {
          appendImageToElement('recent-jobs', 'appended-image5', Jobs);
          driverObj.moveNext();
          localStorage.setItem('walkthroughSeen', 'true');
        },
      },
    },

    {
      element: '#recent-jobs',
      popover: {
        title: 'Recent Jobs',
        description: `Welcome to Recent Jobs for You. Lets find your next career move! ${
          !isPremium
            ? 'Upgrade your plan to view recent jobs.'
            : COMMON.DUMMY_TEXT
        } `,
        side: 'top',
        align: 'start',
        showButtons: ['next', 'close', 'previous'],
        onNextClick: () => {
          appendImageToElement(
            'recent-courses',
            'appended-image6',
            getCoursesImage(),
          );
          driverObj.moveNext();
          localStorage.setItem('walkthroughSeen', 'true');
        },
      },
    },

    createStep(
      '#recent-courses',
      'Recent Courses',
      'Get ready to enhance your skills with Recommended Courses.',
      isPremium,
      'top',
      'start',
      null,
      'Upgrade your plan to view recommended courses.',
    ),
    createStep(
      '#job-section',
      'Jobs Section',
      'Looking for job opportunities? Find them here.',
      isPremium,
      'right',
      'start',
      null,
      'Upgrade your plan to view jobs.',
    ),
    createStep(
      '#book-section',
      'Books Section',
      'Looking for recommended books? Find them here.',
      isPremium,
      'right',
      'start',
      null,
      'Upgrade your plan to view recommended books.',
    ),
    createStep(
      '#upskill-section',
      'Upskill Section',
      'Ready to enhance your skills? Explore these opportunities.',
      isPremium,
      'right',
      'start',
      null,
      'Upgrade your plan to view courses.',
    ),
    createStep(
      '#survey-section',
      'Surveys Section',
      'Your opinion matters. Participate in surveys and provide feedback.',
      isPremium,
      'right',
      'start',
      null,
      'Upgrade your plan to view surveys.',
    ),
    {
      element: '#upgrade-section',
      popover: {
        title: 'Upgrade',
        description: `${
          isPremium
            ? 'Want to change your subscription plan? Manage your payments here.'
            : 'Want to go premium? Manage your payments here.'
        }`,
        side: 'right',
        align: 'start',
        showButtons: ['next', 'close', 'previous'],
      },
    },

    {
      element: '#profile-section',
      popover: {
        title: 'Profile Section',
        description:
          "Welcome to your SkilledScore profile! Let's make it your own. You can update your profile any time.",
        side: 'right',
        align: 'start',
        showButtons: ['next', 'close', 'previous'],
        onNextClick: () => {
          localStorage.setItem('walkthroughSeen', 'true');
          FirebaseAnalyticsEvent('tour_completed');
          window.location.reload();
          setIsWalkthroughRunning(false);
          driverObj.moveNext();
        },
      },
    },
  ];

  const driverObj = driver({
    showProgress: true,
    animate: true,
    popoverClass: 'popover',
    disableActiveInteraction: true,
    steps,
    allowClose: false,
    allowKeyboardControl: false,
    onCloseClick: () => {
      window.location.reload();
      localStorage.setItem('walkthroughSeen', 'true');
    },
  });

  return driverObj;
};

export const authBearer = {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
  },
};

export const getButtonText = (userPayment) => {
  if (userPayment?.payment_status === PAYMENT_STATUS.PAID)
    return 'Already Active';
  return 'Upgrade';
};

export const determineUpgradeStatus = (userPayment, clickedPriceId) => {
  const currentIndex = PREMIUM_PLAN.findIndex(
    (p) => p.priceId === userPayment.payment_id,
  );
  const newIndex = PREMIUM_PLAN.findIndex((p) => p.priceId === clickedPriceId);
  return currentIndex > newIndex ? 'Downgrade' : 'Upgrade';
};
