import { Helmet } from 'react-helmet';

import { Header } from 'src/Components/LandingPage';
import Footer from 'src/Components/LandingPage/Footer';
import { HOW_IT_WORK, SKILLED_SCORE_VIDEOS } from 'src/Constants/Common';

const HowItWorks = () => {
  return (
    <div>
      <Helmet>
        <title>
          AI Skills Assessment Test | Find Your Career-Boosting Skills
        </title>
        <meta
          name='description'
          content='With The Help of Our Detailed Skills Assessment Test, Find Your Strengths. Identify Your Professional Talents And Limitations To Achieve Success. Try It Now!'
        />
      </Helmet>
      <div className='bg-primaryBg px-2'>
        <div className='max-w-screen-2xl mx-auto'>
          <div className='mx-2 lg:px-8'>
            <Header />
          </div>
        </div>
      </div>
      <div className='container w-full mx-auto my-16'>
        <div className='mx-auto mt-11 grid  grid-cols-1 lg:grid-cols-12 '>
          <div></div>
          <div className='px-5 col-span-10'>
            <h1 className='!text-2xl md:!text-3xl font-medium text-center text-DarkBluePurple !font-[IBM-Ple-Sans]'>
              Mapping Your Skills Assessment Test with SkilledScore
            </h1>
            <div className='mt-4'>
              <p className='text-center '>
                {
                  "SkilledScore is more than just a piece of software; it's your road map to realizing your greatest potential in the workplace. By utilizing the International Labour Organization's (ILO) extensive information base and data analytics, SkilledScore enables people to make well-informed career selections based on their specific skill sets, objectives, and geographical location."
                }
              </p>
            </div>
          </div>
          <div></div>
        </div>

        {HOW_IT_WORK.map((text) => (
          <>
            <div className='grid grid-cols-1 lg:grid-cols-12 mt-12 sm:mt-7 px-5 '>
              <div className=''></div>
              <div className='col-span-10 border rounded-xl p-5'>
                <h2 className='!text-xl md:!text-2xl !font-medium !text-center md:!text-left text-black !font-[IBM-Ple-Sans]'>
                  {text.HEADING}
                </h2>
                <div className='mt-4'>
                  <p className='text-center md:text-left'>{text.PARA}</p>
                </div>
              </div>
              <div className=''></div>
            </div>
          </>
        ))}

        <div className='grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-10 mt-10 px-5 md:px-2'>
          {SKILLED_SCORE_VIDEOS?.map((video, ind) => (
            <div key={ind} className='bg-reg-100'>
              <iframe
                allowFullScreen
                title={`how-vid-${SKILLED_SCORE_VIDEOS.title}`}
                src={`https://www.youtube.com/embed/${video.url}`}
                className='mx-auto w-[100%] mt-5 md:w-full h-60 md:h-80 rounded-lg'
              ></iframe>
              <h3 className='font-[karla-regular] text-center md:text-left font-bold  text-xl md:text-2xl text-DarkBluePurple'>
                {video.title}
              </h3>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HowItWorks;
