import React from 'react';

import Benefit1 from '../../../Assets/images/v1/icons/benefits-1.webp';
import Benefit2 from '../../../Assets/images/v1/icons/benefits-2.webp';
// import RightIcon from '../../../Assets/images/v1/icons/right-icon.svg';
import { SectionTitle } from '../SectionTitle';
import { Text } from '../Text';

const BenefitCard = ({ className, img, title, description }) => {
  return (
    <div className={`${className}`}>
      <div>
        <img src={img} className='-top-20 relative left-4 md:left-10' />
      </div>
      <Text className={'text-white text-regular pt-2 xl:pt-6'}>{title}</Text>
      <Text className={'text-white text-base py-6'}>{description}</Text>
      {/* <div className='flex border-t-2 w-max pb-4 cursor-pointer group items-center'>
        <Text
          className={
            'text-white text-xs sm:text-base group-hover:underline pt-2'
          }
        >
          {CTAlabel}
        </Text>
        <img className='pt-2' src={RightIcon} />
      </div> */}
    </div>
  );
};

const Benefits = () => {
  return (
    <div className=' bg-PurpleBlack' id='benefits'>
      <div className='max-w-screen-2xl mx-auto '>
        <SectionTitle
          title='Benefits of joining SkilledScore'
          description='A smarter way to find right skills and jobs.'
          titleClass='text-2xl sm:text-3xl md:!text-[44px]'
          descriptionClass='mt-3'
        />
        <div className='flex flex-col md:flex-row justify-evenly md:pt-48 pt-0 items-center'>
          <div className='w-full px-4 py-24 md:py-0 md:w-3/5 lg:1/3 xl:w-[600px]'>
            <BenefitCard
              className={' bg-BluePurple px-6 md:px-14 pb-16'}
              img={Benefit1}
              title='3X Increase in your career'
              description='Elevate your career to new heights with upskilling through SkilledScore.'
              CTAlabel='Learn More About Career Paths'
            />
          </div>
          <div className='w-full px-4 pt-10 md:pt-0 md:w-3/5 lg:1/3  xl:w-[600px]'>
            <BenefitCard
              className='-mt-0 md:-mt-24 bg-DarkBluePurple px-6 md:px-14 mb-10 pb-16'
              img={Benefit2}
              title='100% Job Satisfaction'
              description={
                'Experience unparalleled job satisfaction as your aspirations, goals, and qualifications align perfectly with your career.'
              }
              CTAlabel='Learn More About Career Paths'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
