/* eslint-disable simple-import-sort/imports */
import { Header } from 'src/Components/LandingPage';
import Footer from 'src/Components/LandingPage/Footer';
import JobAccordion from './JobAccordion';

const Careers = () => {
  const jobs = [
    {
      title: 'Backend Developer (Node.Js)',
      description:
        'We are looking for a skilled Backend Developer to join our dynamic team. As a Backend Developer, you will be responsible for designing and implementing robust and scalable server-side applications...',
      responsibilities: [
        'Develop and maintain server-side logic, ensuring high performance and responsiveness.',
        'Design and implement RESTful APIs to support various front-end functionalities.',
        'Integrate and manage AWS S3 for file storage and retrieval.',
        'Implement and manage database solutions using MySQL and RDS.',
        'Use Sequelize ORM to interact with the database in a secure and efficient manner.',
        'Integrate payment processing using Stripe.',
        'Write clean, maintainable, and efficient code in JavaScript.',
        'Optimize applications for maximum speed and scalability.',
        'Troubleshoot and debug issues to improve performance and usability.',
        'Participate in code reviews and contribute to a high standard of code quality.',
        'Stay up-to-date with the latest industry trends and technologies in backend development.',
      ],
      requirements: [
        'Proven experience as a Backend Developer with a strong portfolio of previous projects.',
        'Proficiency in JavaScript, including ES6+ features.',
        'Solid understanding of AWS S3 for storage solutions.',
        'Experience with Sequelize ORM for database management.',
        'Familiarity with MySQL and RDS for database deployment and management.',
        'Knowledge of integrating payment gateways, particularly Stripe.',
        'Strong problem-solving skills and attention to detail.',
        'Excellent communication and teamwork skills.',
        'Understanding of RESTful API design principles.',
        'Experience with version control systems (e.g., Git).',
        'Ability to write clean, readable, and maintainable code.',
      ],
      contact: 'hr@skilledscore.com',
    },
    {
      title: 'React.js Developer',
      description:
        'We are looking for a skilled React.js Developer to join our dynamic team. As a React.js Developer, you will be responsible for building advanced user interfaces for our web applications...',
      responsibilities: [
        'Develop advanced user interfaces using React.js and related technologies.',
        'Implement responsive design using Tailwind CSS and Material-UI (MUI).',
        'Manage state and side-effects using modern state management libraries and patterns.',
        'Integrate and consume RESTful APIs using Fetch API and Axios.',
        'Write clean, maintainable, and efficient code in TypeScript.',
        'Collaborate with designers to translate UI/UX designs into high-quality code.',
        'Optimize applications for maximum speed and scalability.',
        'Troubleshoot and debug issues to improve performance and usability.',
        'Participate in code reviews and contribute to a high standard of code quality.',
        'Stay up-to-date with the latest industry trends and technologies in front-end development.',
      ],
      requirements: [
        'Proven experience as a React.js Developer with a strong portfolio of previous projects.',
        'Proficiency in React.js, including hooks, context API, and functional components.',
        'Solid understanding of TypeScript and its use in React applications.',
        'Experience with Fetch API and Axios for data fetching.',
        'Familiarity with Material-UI (MUI) and Tailwind CSS for UI development.',
        'Strong knowledge of HTML, CSS, and JavaScript (ES6+).',
        'Understanding of responsive design principles and mobile-first development.',
        'Experience with version control systems (e.g., Git).',
        'Ability to write clean, readable, and maintainable code.',
        'Strong problem-solving skills and attention to detail.',
        'Excellent communication and teamwork skills.',
      ],
    },
  ];
  return (
    <div>
      <div className='bg-primaryBg px-2 '>
        <div className='max-w-screen-2xl mx-auto'>
          <div className='mx-2 lg:px-8'>
            <Header />
          </div>
        </div>
      </div>
      <div className='container w-full mx-auto my-16 px-6 md:px-12 lg:px-20 min-h-[60vh]'>
        <h1 className='!text-3xl md:!text-5xl font-medium text-center text-DarkBluePurple py-5 !font-[IBM-Ple-Sans]'>
          We are Hiring!
        </h1>

        {jobs.map((job, index) => (
          <div className='my-2' key={index}>
            <JobAccordion job={job} />
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
};

export default Careers;
