import React from 'react';
import PropTypes from 'prop-types';

import Dialog from './Dialog';
import EducationForm from './EducationForm';

const EducationInputDialog = (props) => {
  const { open, onClose, ...restProps } = props;

  const onSubmit = async (data) => {
    await props.onSubmitted(data);
    props.onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={props.id === -1 ? 'Add Education' : 'Edit Education'}
    >
      <EducationForm
        {...restProps}
        onSubmitted={async (data) => await onSubmit(data)}
      />
    </Dialog>
  );
};

EducationInputDialog.propTypes = {
  id: PropTypes.number,
  degree: PropTypes.string,
  fieldOfStudy: PropTypes.string,
  grade: PropTypes.string,
  startYear: PropTypes.string,
  endYear: PropTypes.string,
  locationText: PropTypes.string,
  institute: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmitted: PropTypes.any,
};

EducationInputDialog.defaultProps = {
  id: -1,
};

export default EducationInputDialog;
