import React, { useRef, useState } from 'react';
import Slider from 'react-slick';

import AssessmentScoreImage from 'src/Assets/images/v1/assessment-score.webp';
import careerCounselingImage from 'src/Assets/images/v1/career-counseling.webp';
// import RightIcon from 'src/Assets/images/v1/icons/right-icon-black.svg';
import SelectedScore from 'src/Assets/images/v1/icons/selected-score.svg';
import jobsImage from 'src/Assets/images/v1/jobs.webp';
import upskillImage from 'src/Assets/images/v1/upskill.webp';

import { Text } from '../Text';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const HowsWork = ({ title, heading, paragraph, src }) => {
  return (
    <div className='max-w-screen-2xl mx-auto flex flex-wrap h-full md:h-96 items-center'>
      <div className='flex-1 px-4 lg:pl-20 md:pl-20 flex justify-center flex-col'>
        <Text className={'text-BluePurple text-regular'}>{title}</Text>
        <Text
          className={
            'text-secondary text-2xl sm:text-3xl md:!text-[36px] py-4 font-bold !font-[IBM-Ple-Sans] leading-tight'
          }
        >
          {heading}
        </Text>
        <Text className={'md:w-full'}>{paragraph}</Text>
        {/* <div className='flex border-t-2 w-full pb-4 cursor-pointer group items-center'>
          <Text className={'text-base group-hover:underline pt-2'}>
            {`Learn more about ${title?.toLowerCase()}`}
          </Text>
          <div className='pt-2'>
            <img src={RightIcon} />
          </div>
        </div> */}
      </div>
      <div className='flex justify-center xl:block !w-full xl:!w-max px-10 lg:px-0 mt-10 '>
        <img
          src={src}
          className='md-w-[400px] lg:w-[770px] shadow-[1px_1px_35px_-5px_rgba(0,0,0,0.1)]'
        />
      </div>
    </div>
  );
};

const HowItWorksSection = ({ isSelected, onClick, label }) => {
  return (
    <div className='relative overflow-hidden h-24 justify-center'>
      <div
        onClick={onClick}
        className={`px-5 py-3 z-10 rounded-lg cursor-pointer group hover:bg-SSBlack  hover:text-white ${
          isSelected ? 'bg-SSBlack text-white' : 'text-black'
        }`}
      >
        <Text
          className={`group-hover:text-white ${
            isSelected ? 'text-white' : 'text-black'
          }`}
        >
          {label}
        </Text>
      </div>
      {isSelected ? (
        <div className='absolute top-10 -z-10'>
          <img src={SelectedScore} className='w-40 h-20' />
        </div>
      ) : null}
    </div>
  );
};

const HowItWorks = () => {
  const [selected, setSelected] = useState(0);
  const sliderRef = useRef(null);

  const handleToggle = (index) => {
    setSelected(index);
    sliderRef.current.slickGoTo(index);
  };
  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className='pt-5'>
      <div className='md:py-10 py-5 text-center px-2'>
        <h1 className='!text-black !text-2xl sm:!text-3xl md:!text-4xl lg:!text[44px] px-2  md:text-subHeading font-bold !font-[IBM-Ple-Sans] leading-tight'>
          How SkilledScore Works for Your Career Counseling?
        </h1>
      </div>
      <div className=' flex-row justify-evenly hidden md:flex'>
        <HowItWorksSection
          label='Get Assessement Score'
          isSelected={selected === 0}
          onClick={() => handleToggle(0)}
        />

        <HowItWorksSection
          label='Upskills'
          isSelected={selected === 1}
          onClick={() => handleToggle(1)}
        />
        <HowItWorksSection
          label='Career Counseling'
          isSelected={selected === 2}
          onClick={() => handleToggle(2)}
        />
        <HowItWorksSection
          label='Jobs Recommendations'
          isSelected={selected === 3}
          onClick={() => handleToggle(3)}
        />
      </div>
      <div className='bg-lightGrey py-10 w-full px-2'>
        <div className='slider-container overflow-hidden'>
          <Slider
            afterChange={(index) => setSelected(index)}
            {...sliderSettings}
            ref={sliderRef}
            dotsClass='flex px-10 justify-around'
            appendDots={(dots) => (
              <ul className='flex'>
                {dots?.map((dot) => {
                  return (
                    <div
                      key={dot.key}
                      className={`${
                        dot.props.className === 'slick-active'
                          ? 'w-7 !bg-DarkBluePurple '
                          : 'w-4'
                      } h-3 md:w-0 md:h-0 rounded-full bg-slate-300 mt-5`}
                      onClick={dot.props.children.props.onClick}
                    />
                  );
                })}
              </ul>
            )}
          >
            <div>
              <HowsWork
                title='Get Assessment Score'
                heading='Unlock the Power of Self-discovery'
                paragraph='Explore our advanced skill assessment tool to see the full potential of your abilities.'
                src={AssessmentScoreImage}
              />
            </div>

            <div>
              <HowsWork
                title='Upskills'
                heading='Embark on a Transformational Journey - Elevate Your Expertise'
                paragraph='With SkilledScore, advance your skills, dive into a limitless world of continuous growth and improvement in your chosen
                field, empowering you to become the best version of yourself.'
                src={upskillImage}
              />
            </div>
            <div>
              <HowsWork
                title='Career Counseling'
                heading='Set Sail Toward Triumph'
                paragraph="Navigating your career path can be a thrilling adventure; you're never alone with SkilledScore. We provide personalized career counseling to empower you with the insights and guidance you need to make informed, strategic decisions for your professional journey."
                src={careerCounselingImage}
              />
            </div>
            <div>
              <HowsWork
                title='Jobs Recommendations'
                heading='Discover Your Path to Dream Opportunities'
                paragraph="Embark on your journey towards the perfect career with SkilledScore's cutting-edge job recommendation feature, unlocking a world of tailored opportunities perfectly suited to your skills and aspirations."
                src={jobsImage}
              />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
