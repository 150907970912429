import { useHistory } from 'react-router-dom';

import { HOME, POLICIES } from 'src/Constants/Common';
import { scrollSmoothTo } from 'src/Utils/utils';

// const Link = ({ href, label }) => {
//   return (
//     <li className='mb-3 font-[Karla-Regular]'>
//       <a href={href} className='hover:underline'>
//         {label}
//       </a>
//     </li>
//   );
// };

const FooterRight = () => {
  const history = useHistory();

  return (
    <div className='grid text-lg grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
      <div className='md:px-8 mb-8 sm:mb-0'>
        <h2 className='mb-5 font-[Karla-Regular] text-white uppercase dark:text-white'>
          Home
        </h2>
        <ul className='text-gray-500 dark:text-gray-400 font-medium'>
          {HOME.map(({ name, id, type }, ind) => (
            <p
              key={ind}
              className='!font-[Karla-Regular] text-lg text-gray-500 cursor-pointer hover:underline'
              onClick={() =>
                type === 'scroll'
                  ? scrollSmoothTo(id, '/', history)
                  : history.push(id)
              }
            >
              {name}
            </p>
          ))}
        </ul>
      </div>
      <div className='md:px-8 mb-8 sm:mb-0'>
        <h2 className='mb-5 font-[Karla-Regular] text-white uppercase dark:text-white'>
          Information
        </h2>
        <ul className='text-gray-500 dark:text-gray-400 font-medium'>
          {POLICIES.map(({ name, path }, ind) => (
            <p
              key={ind}
              className='!font-[Karla-Regular] text-lg text-gray-500 cursor-pointer hover:underline md:whitespace-nowrap'
              onClick={() => history.push(path)}
            >
              {name}
            </p>
          ))}
        </ul>
      </div>
      {/* <div className='md:px-8 mb-8 sm:mb-0'>
        <h2 className='mb-5 font-[Karla-Regular] text-white uppercase dark:text-white'>
          Products
        </h2>
        <ul className='text-gray-500 dark:text-gray-400 font-medium'>
          <Link href={'#'} label='Design Systems' />
          <Link href={'#'} label='Themes &amp; Templates' />
          <Link href={'#'} label='Mockups' />
          <Link href={'#'} label='Presentations' />
          <Link href={'#'} label='Wireframes Kits' />
          <Link href={'#'} label='Ui Kits' />
        </ul>
      </div>
      <div className='md:px-8 mb-8 sm:mb-0'>
        <h2 className='mb-5 font-[Karla-Regular] text-white uppercase dark:text-white'>
          Legals
        </h2>
        <ul className='text-gray-500 dark:text-gray-400 font-medium'>
          <Link href={'#'} label='License' />
          <Link href={'#'} label='Refund Policy' />
          <Link href={'#'} label='About Us' />
          <Link href={'#'} label='Contacts' />
        </ul>
      </div>
      <div className='md:px-8'>
        <h2 className='mb-5 font-[Karla-Regular] text-white uppercase dark:text-white'>
          Blogs
        </h2>
        <ul className='text-gray-500 dark:text-gray-400 font-medium'>
          <Link href={'#'} label='Business Stories' />
          <Link href={'#'} label='Digital Store' />
          <Link href={'#'} label='Learning' />
          <Link href={'#'} label='Social Media' />
        </ul>
      </div> */}
    </div>
  );
};

export default FooterRight;
