import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Header } from 'src/Components/LandingPage';
import LoaderButton from 'src/Components/LoaderButton';
import Routes from 'src/Utils/Routes';

// import { AppContext } from 'src/Contexts';
import { Auth } from '../../../Api';
import Logo from '../../../Assets/images/v1/icons/logo.svg';
import Avatar from '../../../Assets/images/v1/icons/sign-in-avatar.svg';
import FooterHelpLink from '../FooterHelpLink';

const ForgetPassword = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    }),
    onSubmit: async (values) => {
      await onSubmit(values.email);
    },
  });

  const onSubmit = async (email) => {
    setLoader(true);
    setErrorMessage('');
    try {
      const response = await Auth.forgotPassword(email);
      if (response.status) {
        setLoader(false);
        setIsSubmitted(true);
        // props.onSubmit();
      }
    } catch (e) {
      setErrorMessage(e.message);
      setLoader(false);
    }
  };

  return (
    <div className='flex flex-col md:flex-row bg-lightGrey h-screen md:h-full justify-between'>
      <div className='block md:hidden bg-primaryBg px-4'>
        <Header />
      </div>
      <div className='bg-primaryBg hidden md:block w-full md:w-2/5 px-4 md:px-14 h-screen min-h-[800px] text-white'>
        <Link to='/'>
          <img className='mt-10' src={Logo} alt='logo' />
        </Link>
        <div className='h-4/5 '>
          <img className='w-auto mt-10 mx-auto' src={Avatar} alt='avatar' />
          <h1 className='mx-auto text-xl font-[Karla-Bold] my-5 uppercase'>
            Forget Password
          </h1>
          <p className=' mx-auto font-[Karla-regular] text-lightGrey'>
            Enter your registered email id here to get a reset password link.
            The reset password link will be sent to your registered email
            address
          </p>
        </div>

        <div className='hidden md:block'>
          <FooterHelpLink />
        </div>
      </div>
      <div className='flex flex-col md:justify-center mx-auto my-10'>
        <div className=' mx-auto flex flex-col justify-center md:mx-auto w-full md:w-96'>
          {isSubmitted ? (
            <>
              <div className='px-10 py-4 my-3 rounded-xl'>
                Reset link have been sent to your email.
              </div>
              <button
                onClick={() => history.push('/sign-in')}
                className='flex justify-center  bg-BluePurple text-white my-1.5 font-medium w-full  rounded-lg text-sm py-3  '
              >
                Go back to Login
              </button>
            </>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <div className='w-full text-center p-4'>
                <h1 className='text-3xl my-5 font-bold text-gray-900'>
                  Forget Password
                </h1>
                <p className='text-lg my-5'>
                  Please enter your registered email to help you access your
                  account
                </p>
                <input
                  type='text'
                  className='border-2 w-full h-12 px-3 mb-3 rounded-lg'
                  placeholder='Email'
                  value={formik.values.email}
                  name='email'
                  id='email'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />

                {(formik.touched.email && formik.errors.email) ||
                (errorMessage && !formik.errors.email) ? (
                  <div className='text-start mb-2 text-red-500'>
                    {formik.errors.email || errorMessage}
                  </div>
                ) : null}

                {loader ? (
                  <LoaderButton />
                ) : (
                  <button
                    type='submit'
                    className='flex justify-center  bg-BluePurple text-white font-medium w-full  rounded-lg text-sm py-3  '
                  >
                    SEND
                  </button>
                )}
                <p className='flex justify-end  text-BluePurple  hover:cursor-pointer'>
                  <Link to={Routes.Login}>Back to Login?</Link>
                </p>
              </div>
            </form>
          )}
        </div>
      </div>
      <div className='block md:hidden pb-6 bg-lightGrey'>
        <FooterHelpLink linkStyle=' text-black' iconColor='#000' />
      </div>
    </div>
  );
};
export default ForgetPassword;
