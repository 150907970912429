import {
  GufhtuguHorizontal,
  KangarooHorizontal,
  ZindabhagHorizontal,
} from 'src/Assets/images/banner';
import { SanaRasheed, SirZeeshan } from 'src/Assets/images/dashboard';
import Routes from 'src/Utils/Routes';

const PAYMENT_CARD_HEADINGS = [
  'Plan Type',
  'Payment',
  'Amount',
  'Discount',
  'Payment Date',
  'Action',
];

const COMMON = {
  REQUIRED_ERROR_MSG: 'Required fields cannot be empty',
  PASSWORD_VALIDATION:
    'Must contain 8 characters, at-least one uppercase, one lowercase and one number.',
  PASSWORD_MAX_LENGTH: 'Password must not exceed 20 characters.',
  PAYMENT_TEXT:
    'Elevate your SkilledScore experience with our enhanced plan for expanded opportunities.',
  PAYMENT_HISTORY: 'View your complete payment history here.',
  SKILL_SCORE_TEXT_1: `Skilled Score is a comprehensive metric that provides valuable
  insights into your career prospects. This score helps you to
  understand your strengths and weaknesses, providing you with the
  necessary information to make informed decisions regarding your career
  path, training, and skill development.`,
  SKILL_SCORE_TEXT_2: `The Skilled Score is a powerful tool that can assist you in planning
  your career and self-assessment. It empowers you to thrive in the
  current job market and prepare you for future opportunities.`,
  DUMMY_TEXT: 'This is an example that you are viewing',
  PREMIUM_TEXT: 'Please upgrade to premium to view your score.',
  NO_REFUND: 'Disclaimer: Non-Refundable Fee.',
  CHOOSE_PRICING_PLAN: 'Choose your pricing plan',
  OUR_TEAM: 'Our Team',
  BLOGS: 'Blogs',
  PRICING_PLAN_TEXT:
    'Explore our flexible payment plans to find the option that best fits your needs. Achieve your goals with ease as you choose a plan that aligns with your budget and timeline. Discover the possibilities and start your journey with SkilledScore today!',
};

const POLICIES = [
  { name: 'Privacy Policy', path: Routes.PrivacyPolicy },
  { name: 'Terms and Conditions', path: Routes.TermsAndCondition },
  { name: 'Cookie Policy', path: Routes.CookiesPolicy },
];

const HOME = [
  { name: 'Benefits', id: 'benefits', type: 'scroll' },
  { name: 'How to Use?', id: 'how-to-use', type: 'scroll' },
  { name: 'Pricing', id: 'pricing-plan', type: 'link' },
];

const PAYMENT_STATUS = {
  PAID: 'paid',
  NOT_PAID: 'not paid',
};
const ILO_CHART_WIDTH_MAPPER = {
  1: 10.5,
  2: 16.8,
  3: 24.15,
  4: 31.5,
  5: 40.95,
  6: 50.4,
  7: 57.75,
  8: 66.15,
  9: 73.5,
};
const ILO_GROUPS = [
  'Armed Forces Occupations',
  'Managers',
  'Professionals',
  'Technicians and Associate Professionals',
  'Clerical Support Workers',
  'Services And Sales Workers',
  'Skilled Agricultural, Forestry and Fishery Workers',
  'Craft and Related Trades Workers',
  'Plant and Machine Operators and Assemblers',
  'Elementary Occupations',
];

const PREMIUM_PLAN = [
  {
    title: 'Monthly Plan',
    amount: '20',
    priceId: process.env.REACT_APP_STRIPE_MONTHLY_PRICE_KEY,
    duration: '1 Month',
    validity: 30,
  },
  {
    title: 'Quarterly Plan',
    amount: '50',
    priceId: process.env.REACT_APP_STRIPE_QUARTERLY_PRICE_KEY,
    duration: '3 Months',
    validity: 90,
  },
  {
    title: 'One Time Payment',
    amount: '20',
    amountPKR: '3000',
    priceId: process.env.REACT_APP_STRIPE_ONE_TIME_PRICE_KEY,
    duration: 'Package for lifetime',
  },
  {
    title: 'Full Time Access Payment',
    amount: '20',
    amountPKR: '3000',
    priceId: process.env.REACT_APP_STRIPE_FULL_TIME_ACCESS_PRICE_KEY,
    duration: 'Package for lifetime',
  },
  {
    title: 'Yearly Plan',
    amount: '150',
    priceId: process.env.REACT_APP_STRIPE_YEARLY_PRICE_KEY,
    duration: '1 Year',
    validity: 365,
  },
];

const PLAN_INFO = [
  { description: 'View profile information.' },
  { description: 'Edit profile information.' },
  {
    description: 'Assessment score calculation.',
  },
  {
    description: 'Recommended jobs.',
  },
  {
    description: 'Recommended courses.',
  },
  {
    description: 'Recommended surveys.',
  },
  {
    description: 'Upload resume upto 5 MB.',
  },
];

const PAYMENT_METHODS = {
  STRIPE: 'stripe',
  EASY_PAISA: 'easyPaisa',
};
const BANNER_LINK = [
  'https://gufhtugu.com/',
  'https://zindabhag.com/',
  'https://www.kangaroo.ventures',
];

const USER_PROFILE = {
  NO_SUMMARY: 'No summary has been added yet',
  NO_SKILLS: 'No skills have been added yet',
  NO_EXPERIENCE: 'No experiences have been added yet',
  NO_EDUCATION: 'No educations have been added yet',
  NO_INTEREST: 'No interests have been added yet',
  NO_PROJECT: 'No projects have been added yet',
  NO_CERTIFICATE: 'No certificates have been added yet',
};
const EASY_PAISA_STATUS = {
  SUCCESS: 'SUCCESS',
  ACCOUNT_DOES_NOT_EXIST: 'ACCOUNT DOES NOT EXIST',
  SYSTEM_ERROR:
    'You either have Unregistered account or incorrect PIN or have not approved transaction from EasyPaisa',
};

const EASY_PAISA_APPROVAL_POINTS = [
  'Ensure that in-app notifications for the EasyPaisa app are enabled.',
  'Login to your EasyPaisa app. Tap on the top-left menu My Account.',
  'To approve the payment under My Approvals. Ensure your EasyPaisa account is Active.',
];

const MESSAGES = [
  'Processing Your Resume...',
  "We're Analyzing Your Skills...",
  'Data Extraction in Progress...',
  'Preparing Your Profile...',
];
const FILE_SIZES = {
  PREMIUM_USER: 5,
  FREE_USER: 2,
};

const BANNER_IMAGES = [
  GufhtuguHorizontal,
  ZindabhagHorizontal,
  KangarooHorizontal,
];

const SKILLED_SCORE_VIDEOS = [
  {
    url: '/1D5mSyL4RjE?si=R-yrB1lqE8COC7UU',
    title: 'SkilledScore Demo (English)',
  },
  {
    url: '/042g0Xxu038?si=myCX-LBwsFiYa7Eo',
    title: 'SkilledScore Demo (Urdu)',
  },
];

const SCORE_DETAILs = [
  [
    {
      heading: '',
      details: [
        '1. Enhance skills and qualifications.',
        '2. Prepare for future job markets.',
        '3. Improve career opportunities.',
      ],
    },
  ],

  [
    {
      heading: 'Course Selection for Adaptive Skill Development',
      details: [
        'a. Lifelong Learning: Embrace continuous learning by staying updated with industry trends and emerging technologies through courses, workshops, and online resources.',
        'b. Knowledge Expansion: Invest time reading books, articles, and research to broaden your knowledge and keep your skills relevant.',
      ],
    },
    {
      heading: 'Adaptability and Resilience',
      details: [
        'a. Embracing Change: Develop a mindset that welcomes change, new ideas, and technologies, allowing you to adapt swiftly to evolving situations.',
        'b. Resilience Building: View setbacks as opportunities for growth and learning, cultivating resilience in facing challenges.',
      ],
    },
    {
      heading: 'Professional Growth and Networking',
      details: [
        'a. Mentorship and Feedback: Seek guidance and feedback from experienced professionals and mentors in your field to refine your skills and knowledge.',
        'b. Networking and Collaboration: Build a solid professional network by attending industry events and collaborating with colleagues from diverse backgrounds to enhance your teamwork skills.',
      ],
    },
  ],
  [
    {
      heading: 'Research and Targeting',
      details: [
        'a. Identify regions or cities aligned with your career goals and industry preferences.',
        'b. Narrow down your job search to specific geographical regions for focused efforts.',
      ],
    },
    {
      heading: 'Network and Connect',
      details: [
        'a. Build a solid professional network in your chosen geographic area.',
        'b. Connect with local professionals on LinkedIn for job insights and to expand your network.',
        'c. Leverage your network for insights on job openings, company cultures, and the local job market.',
      ],
    },
    {
      heading: 'Skill Enhancement',
      details: [
        'a. Tailor your skill set to meet local job market demands.',
        'b. Identify skills or certifications in high demand in your target area.',
        'c. Invest in professional development and online courses to enhance qualifications.',
      ],
    },
  ],
  [
    {
      heading: 'Continuous Learning and Growth Commitment',
      details: [
        'a. Online Certifications: Pursue online certifications and micro-credentials to gain specialized knowledge and skills.',
        'b. Mentoring and Coaching: Seek mentorship or coaching to accelerate personal and professional growth.',
        'c. Learning Communities: Join online or local learning communities and exchange peer-to-peer knowledge.',
      ],
    },
    {
      heading: 'Adaptability and Change Management',
      details: [
        'a. Embrace Change Diversity: Develop an understanding and appreciation for diverse perspectives and cultures, preparing you to adapt to different environments effectively.',
        'b. Digital Literacy: Enhance your digital literacy by familiarizing yourself with various digital tools and platforms, enabling seamless adaptation to technological shifts.',
      ],
    },
    {
      heading: 'Innovation and Creative Thinking',
      details: [
        'a. Brainstorming Techniques: Learn and apply various brainstorming techniques to generate innovative ideas.',
        'b. Intrapreneurship: Explore opportunities for intrapreneurship within your organization, fostering innovation from within.',
        'c. Creative Hobbies: Pursue creative hobbies outside work to keep your creative juices flowing and maintain a fresh perspective.',
      ],
    },
  ],
  [
    {
      heading: 'Economic Wealth and Social Support',
      details: [
        'a. Higher GDP per capita provides access to better living standards, healthcare, and education.',
        'b. A strong social safety net ensures support from family and others in times of need.',
      ],
    },
    {
      heading: 'Health and Freedom of Choice',
      details: [
        'a. Life expectancy reflects population health and access to quality healthcare.',
        'b. Freedom to make key life decisions, like career and family planning, influences well-being.',
      ],
    },
    {
      heading: 'Generosity and Trust',
      details: [
        'a. Willingness to help others, through charitable acts, contributes to social cohesion.',
        'b. Low levels of corruption and trust in government build a sense of security and stability.',
      ],
    },
    {
      heading: 'Mental and Physical Well-being',
      details: [
        'a. Emotional balance, mental health, and overall life satisfaction are key indicators of happiness.',
        'b. Physical health and fitness are also crucial factors in quality of life.',
      ],
    },
  ],
];
const HOW_IT_WORK = [
  {
    HEADING: 'How Does It Work?',
    PARA: "SkilledScore is more than just a piece of software; it's your road map to realizing your greatest potential in the workplace. By utilizing the International Labour Organization's (ILO) extensive information base and data analytics, SkilledScore enables people to make well-informed career selections based on their specific skill sets, objectives, and geographical location.",
  },
  {
    HEADING: 'Precision Scoring From 0 to 100',
    PARA: "With a score between 0 and 100, SkilledScore's scoring system gives users a brief and straightforward rating for their abilities. Using this score as a reference, people may accurately assess their areas of strength and growth. SkilledScore provides insightful information to help you navigate your career path, regardless of your background—you might be a recent graduate looking for entry-level positions or an experienced professional trying to switch to a different industry.",
  },
  {
    HEADING: 'Job Recommendation with SkilledScore',
    PARA: "However, SkilledScore is more than just a skills assessment test; it's also a certified career counselor for advancing your education and navigating the job market. SkilledScore uses a large database and strong algorithms to provide recommendations that are specific to your skill level. SkilledScore gives you everything that you need to succeed in today's competitive environment, whether it's by proposing relevant job vacancies in your area or courses and certifications to expand your skill set.",
  },
];

const TEAM_MEMBERS = [
  {
    name: 'Zeeshan Usmani',
    role: 'CTO/Co-founder',
    description:
      "With 10+ years in data science and marketing analytics, I lead Walee's team in advanced analytics, strategic partnerships, media buying, consumer profiling, and influencer management, driving innovative solutions in social and headless commerce.",
    image: SirZeeshan,
    linkedin: 'https://www.linkedin.com/in/zusmani/',
  },
  {
    name: 'Sana Rasheed',
    role: 'CTO/Co-founder',
    description:
      "I provide value through data science (R, Python), predictive analytics (ML, NLTK, SciKit), visualization (Pentaho, Tableau, QlikView, D3), and deep learning. I'm also versed in blockchain and NFTs (Ethereum, Smart Contracts, Bitcoin) for finance, travel, and supply chain.",
    image: SanaRasheed,
    linkedin: 'https://www.linkedin.com/in/sanarasheed/',
  },
];

export {
  BANNER_IMAGES,
  BANNER_LINK,
  COMMON,
  EASY_PAISA_APPROVAL_POINTS,
  EASY_PAISA_STATUS,
  FILE_SIZES,
  HOME,
  HOW_IT_WORK,
  ILO_CHART_WIDTH_MAPPER,
  ILO_GROUPS,
  MESSAGES,
  PAYMENT_CARD_HEADINGS,
  PAYMENT_METHODS,
  PAYMENT_STATUS,
  PLAN_INFO,
  POLICIES,
  PREMIUM_PLAN,
  SCORE_DETAILs,
  SKILLED_SCORE_VIDEOS,
  TEAM_MEMBERS,
  USER_PROFILE,
};
