import React, { useState } from 'react';

import { formatDate } from 'src/Utils/utils';

import Loader from './Loader';

const PaymentCard = ({
  planInfo,
  amount,
  buttonText,
  disable,
  onClick,
  duration,
  isActive,
  cardStyle,
  amountPKR,
  userLocation,
  currencyLoading,
  discountStatus,
  AmountStatus,
}) => {
  const [loading, setLoading] = useState(false);

  const handleOnClick = async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  };

  const renderAmount = (amount, userLocation) => {
    return userLocation === 'PK' ? (
      <>
        <span className='font-semibold'>Rs. </span>
        {Number(amount).toLocaleString()}
      </>
    ) : (
      <>
        <span className='font-semibold'>$</span>
        {amount.toLocaleString()}
      </>
    );
  };

  const discountedAmountPKR = discountStatus
    ? amountPKR * (1 - discountStatus / 100)
    : amountPKR;

  const discountedAmount = discountStatus
    ? amount * (1 - discountStatus / 100)
    : amount;

  return (
    <div
      className={`w-full mt-8 max-w-sm border border-gray-200 rounded-lg shadow-sm p-8 dark:bg-gray-800 dark:border-gray-700 mx-4 h-full flex flex-col justify-between transform transition-transform hover:translate-y-[-10px] hover:shadow-2xl ${cardStyle} !border-DarkBluePurple border-2 ${
        isActive ? 'bg-primaryBg' : 'bg-white'
      }`}
    >
      <div>
        <h5
          className={`fixed top-5 right-5 text-xs font-semibold rounded-full px-4 py-2 ${
            isActive ? 'bg-white text-primaryBg' : 'bg-primaryBg text-white'
          }`}
        >
          {isActive ? 'Active' : 'Without Tax'}
        </h5>

        {!isActive && !currencyLoading && userLocation && (
          <>
            {discountStatus ? (
              <>
                <div
                  className={`text-xl font-bold tracking-tight ${
                    isActive ? 'text-white' : 'text-primaryBg'
                  } line-through opacity-60`}
                >
                  {renderAmount(
                    userLocation === 'PK' ? amountPKR : amount,
                    userLocation,
                  )}
                </div>

                <div
                  className={`text-[40px] font-extrabold tracking-tight ${
                    isActive ? 'text-white' : 'text-primaryBg'
                  }`}
                >
                  {renderAmount(
                    userLocation === 'PK'
                      ? discountedAmountPKR
                      : discountedAmount,
                    userLocation,
                  )}
                  <span className='text-lg font-normal ml-1'>/ {duration}</span>
                </div>

                <span
                  className={`inline-flex items-center justify-start text-md font-bold ${
                    isActive ? 'text-white' : 'text-primaryBg'
                  } rounded-md px-2 transition-transform transform hover:scale-105`}
                >
                  (<sub>{discountStatus}% OFF</sub>)
                </span>
              </>
            ) : (
              <div
                className={`text-3xl font-extrabold tracking-tight mt-6 ${
                  isActive ? 'text-white' : 'text-primaryBg'
                }`}
              >
                {renderAmount(
                  userLocation === 'PK' ? amountPKR : amount,
                  userLocation,
                )}
                <span className='text-lg font-normal ml-1'>/ {duration}</span>
              </div>
            )}
          </>
        )}

        {isActive &&
          (AmountStatus?.is_expiry_date === null ? (
            <div className='flex flex-col items-start mt-6'>
              <span className=' text-2xl font-bold tracking-tight text-white'>
                Lifetime Package Active
              </span>
            </div>
          ) : (
            <div className='flex flex-col items-start mt-6'>
              <span className='text-3xl font-bold text-white'>
                Package Active
              </span>
              <span className='text-base font-semibold text-white mt-1'>
                till{' '}
                {formatDate(
                  new Date(AmountStatus?.is_expiry_date),
                  'dd MMMM yyyy',
                )}
              </span>
            </div>
          ))}

        <ul role='list' className='space-y-4 md:space-y-5 my-4'>
          {planInfo.map(({ description }, index) => (
            <li className='flex space-x-3 items-center' key={index}>
              <svg
                className={`flex-shrink-0 w-4 h-4 dark:text-primaryBg ${
                  isActive ? 'text-white' : 'text-primaryBg'
                }`}
                aria-hidden='true'
                xmlns='http://www.w3.org/2000/svg'
                fill='currentColor'
                viewBox='0 0 20 20'
              >
                <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z' />
              </svg>
              <span
                className={`text-base font-normal leading-tight text-gray-500 dark:text-gray-400 ${
                  isActive ? 'text-white' : 'text-primaryBg'
                }`}
              >
                {description}
              </span>
            </li>
          ))}
        </ul>
      </div>

      <button
        type='button'
        disabled={disable}
        onClick={handleOnClick}
        className={`text-white mt-1 focus:ring-4 focus:outline-none focus:ring-blue-200 dark:focus:ring-blue-900 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center disabled:cursor-not-allowed ${
          isActive ? 'bg-white !text-primaryBg' : 'bg-primaryBg text-white'
        }`}
      >
        {loading ? <Loader className='!h-5 !w-5' /> : buttonText}
      </button>
    </div>
  );
};

export default PaymentCard;
