// Accordion.jsx
import React, { useState } from 'react';

const Accordion = ({ title, description, children, containerStyle, icon }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`flex w-full flex-col bg-white border rounded-md px-2 md:px-5 py-3 ${containerStyle}`}
    >
      <button
        onClick={toggleAccordion}
        className='box-border flex cursor-pointer appearance-none items-center justify-between pb-2 focus:outline-none'
      >
        <div>
          <h2 className='!text-xl md:!text-2xl !font-medium !text-center md:!text-left text-DarkBluePurple !font-[IBM-Ple-Sans] '>
            {title}
          </h2>
          <div className='mt-2'>
            <p className='text-center text-xs md:text-sm lg:text-lg md:text-left leading-5 md:leading-6'>
              {description}
            </p>
          </div>
        </div>

        {icon ? (
          <div className={`${isOpen ? ' rotate-180' : ''}`}>{icon}</div>
        ) : null}
      </button>
      <div
        className={`overflow-hidden transition-all duration-700 ease-in-out ${
          isOpen ? 'max-h-screen' : 'max-h-0'
        }`}
      >
        <div className='pt-1'>{children}</div>
      </div>
    </div>
  );
};

export default Accordion;
