const FeaturesBottomRight = () => {
  const array = [
    "Skill Assessment Tool: Make well-informed decisions about your career path by using SkilledScore's extensive tools to obtain deep insights into your areas of strength and progress.",
    'Customized Recommendations: Take advantage of specially designed employment and course recommendations that match your interests, talents, and professional objectives. This way, you can pursue possibilities that are a great fit.',
    'Continuous Growth: Use SkilledScore resources, a skill assessment platform, to start a journey of professional advancement and skill development that will provide you with the know-how and instruments you need to succeed in your chosen sector.',
  ];

  return array.map((data) => {
    return (
      <div key={data}>
        <ul>
          <li className='list-disc mt-8 text-base lg:ml-24'>{data}</li>
        </ul>
      </div>
    );
  });
};
export default FeaturesBottomRight;
