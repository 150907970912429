/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useHistory } from 'react-router-dom';

import ELO from '../../../Assets/images/v1/Breakdown.webp';
import Arrow from '../../../Assets/images/v1/icons/ArrowUpRight.svg';
import Cap from '../../../Assets/images/v1/icons/cap.svg';
import Diamond from '../../../Assets/images/v1/icons/diamond.svg';
import { Text } from '../Text';

const GrowthSection = () => {
  const history = useHistory();

  return (
    <div className='px-4 sm:px-10 md:px-20 md:py-20 py-10 '>
      <div className='max-w-screen-2xl mx-auto flex flex-col md:flex-row '>
        <div className='w-full md:w-1/2 flex align-middle justify-center'>
          <div className='md:px-0 md:w-96 w-full h-full md:min-h-[300px] md:min-w-[300px]'>
            <img
              src={ELO}
              className='max-w-full'
              // className='md:px-0 md:w-96 w-full h-full md:min-h-[300px] md:min-w-[300px]'
            />
          </div>
        </div>
        <div className='w-full md:w-1/2 flex px-4 lg:px-10 flex-col justify-center items-center'>
          <Text className='font-bold w-full mt-4 !text-2xl text-center md:text-left md:!text-4xl text-[44px] tracking-tight pb-10 !font-[IBM-Ple-Sans] leading-tight'>
            A Great Team Behind You: SkilledScore's Path to Success
          </Text>
          <Text className={'text-base w-full'}>
            {`SkilledScore is your key to improving employment prospects, future preparation, and ILO level. 
            You will be well-prepared to succeed in the global job market by using our specialized tests, 
            skill development materials, and career guidance counselor, setting the basis for a fruitful and satisfying professional journey.`}
          </Text>
          <div className='flex w-full mt-4 flex-wrap text-center justify-center md:justify-start md:text-left xl:gap-x-20 gap-y-5 gap-x-10'>
            <div className='mt-7'>
              <div className='flex justify-center md:justify-start'>
                <img src={Diamond} />
              </div>
              <div>
                <Text className='text-base py-2'>Future Readiness</Text>
                <Text className='text-base w-60'>
                  SkilledScore equips you with the skills and guidance needed to
                  thrive in an ever-changing job market, ensuring you are
                  prepared for the challenges and opportunities of the future.
                </Text>
              </div>
            </div>
            <div className='pt-7'>
              <div className='flex justify-center md:justify-start'>
                <img src={Cap} />
              </div>
              <Text className='text-base py-2'>Skills Readiness</Text>
              <Text className='text-base w-60'>
                SkilledScore empowers you to boost your skills, aligning them
                with job market demands to position you as a top candidate for
                your dream career.
              </Text>
            </div>
          </div>
          <div className=' mx-auto md:ml-0 mt-10 cursor-pointer'>
            <button
              onClick={() => {
                history.push('/sign-up');
              }}
              className='text-black border font-medium border-black flex justify-center items-center px-3 py-2 rounded-md'
            >
              Get Started with Skill Score <img className='ml-3' src={Arrow} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrowthSection;
