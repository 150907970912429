import React, { useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Threads from 'src/Api/Threads';
import { AppContext } from 'src/Contexts';
import { FirebaseAnalyticsEvent } from 'src/Firebase/analytics';

import { Auth } from '../../../Api';
import Avatar from '../../../Assets/images/v1/icons/sign-in-avatar.svg';
import { Auth as AuthConstants } from '../../../Constants';
import LoginSignupComponent from '../../LoginSignupComponents';

function LoginNew() {
  const { state } = useLocation();
  const history = useHistory();
  const { fetchUserPayment } = useContext(AppContext);

  const default_email = state?.email;
  const [, setCookie] = useCookies([AuthConstants.JWT_TOKEN]);
  const [error, setError] = useState({ show: false, msg: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);

  const onSubmit = async (email, password) => {
    setError({ show: false, msg: '' });
    setLoader(true);
    try {
      const res = await Auth.signIn(email, password);
      const threadData = await Threads.getThread(res?.data?.data?.token);

      localStorage.setItem(
        'loginData',
        JSON.stringify({
          user: {
            ...res.data.data,
            thread_id: threadData?.data?.data?.id ?? null,
            content: threadData?.data?.data?.content ?? '',
          },
        }),
      );
      setCookie(AuthConstants.JWT_TOKEN, res.data.data.token, {
        path: '/',
      });
      fetchUserPayment(res?.data?.data?.token);
      setLoader(false);
      FirebaseAnalyticsEvent('login');
      history.push('/home');
    } catch (e) {
      setError({ show: true, msg: e.message });
      setLoader(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      email: default_email ?? '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Password is required'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    }),
    onSubmit: async (values) => {
      await onSubmit(values.email, values.password);
    },
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <LoginSignupComponent
        MainHeading='100% JOB SATISFACTION GUARANTEE!'
        Paragraph='Achieve your professional goals with SkilledScore. SkilledScore
              helped me secure a new job which I was dreaming of.'
        Avatar={Avatar}
        Heading=' Log in to your account'
        SubHeading='Welcome back! Please enter your details.'
        ButtonText='Login'
        CreateOrAlready={"Don't have an account?"}
        EndText='Create an account'
        login={true}
        formik={formik}
        error={error}
        loader={loader}
        showPassword={showPassword}
        togglePasswordVisibility={togglePasswordVisibility}
      />
    </div>
  );
}

export default LoginNew;
