/* eslint-disable max-lines */
/* eslint-disable simple-import-sort/imports */

import { Link } from 'react-router-dom';

import { COMMON } from 'src/Constants';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../Assets/images/v1/icons/logo.svg';

import FooterHelpLink from './v1/FooterHelpLink';
import { InputComponent, ToggleButtonComponent } from './ComponentsLoginSignup';
import DateSelector from './DateSelector';
import GoogleSignOn from './GoogleSignOn';
import MicrosoftSignOn from './MicrosoftSignOn';
import { Header } from './LandingPage';
import LoaderButton from './LoaderButton';

const LoginSignupComponent = ({
  MainHeading,
  Paragraph,
  Avatar,
  Heading,
  SubHeading,
  ButtonText,
  CreateOrAlready,
  EndText,
  login,
  formik,
  showPassword,
  togglePasswordVisibility,
  toggleConfirmPasswordVisibility,
  error,
  showConfirmPassword,
  loader,
  setDob,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    formik.submitForm();
  };

  const useStyles = makeStyles(() => ({
    buttonContainer: {
      marginTop: '24px',
    },

    divider: {
      marginTop: '20px',
      marginBottom: '20px',
    },
    dividerText: {
      'marginTop': '20px',
      'marginBottom': '20px',
      'display': 'flex',
      'alignItems': 'center',
      'justifyContent': 'center',
      '&, &:before, &:after': {
        content: '&nbsp;',
        height: '1px',
        backgroundColor: '#dbdbdb',
        fontSize: '14px',
      },
      '& span': {
        backgroundColor: '#f2f3f7',
        paddingLeft: '1em',
        paddingRight: '1em',
      },
    },
    forgot: {
      textAlign: 'right',
    },
    accountMessage: {
      textAlign: 'center',
      marginTop: '16px',
    },
  }));

  const styles = useStyles();

  return (
    <div className='flex flex-col md:flex-row bg-lightGrey h-screen md:h-full justify-between'>
      <div className='block md:hidden bg-primaryBg px-4'>
        <Header />
      </div>
      <div className='bg-primaryBg hidden md:block w-full md:w-2/5 px-4 md:px-14 h-screen min-h-[800px] text-white'>
        <Link to='/' className='hidden md:block'>
          <img className='md:my-10 mt-5' src={Logo} alt='logo' />
        </Link>
        <div className='md:mt-10 h-4/5'>
          <img className='w-auto mt-10 mx-auto' src={Avatar} alt='avatar' />
          <h1 className=' mx-auto text-xl font-[Karla-Bold] md:my-7 my-3'>
            {MainHeading}
          </h1>
          <p className=' mx-auto font-[Karla-regular] text-lightGrey'>
            {Paragraph}
          </p>
        </div>

        <div className='hidden md:block'>
          <FooterHelpLink />
        </div>
      </div>
      <div
        className={
          login
            ? 'flex flex-col md:justify-center mx-auto my-10'
            : ' mx-auto flex flex-col md:justify-center bg-lightGrey'
        }
      >
        <div className='w-full md:w-96 text-center p-4'>
          <h1 className='text-3xl mb-3 font-bold text-gray-900'>{Heading}</h1>
          <p className='text-lg my-3'>{SubHeading}</p>
          <div className='mb-0'>
            {login ? (
              <form onSubmit={(e) => handleSubmit(e)}>
                <InputComponent
                  type='email'
                  text='email'
                  placeholder='Enter your email'
                  formik={formik}
                  value={formik.values.email}
                  autoComplete='email'
                  error={formik.touched.email && formik.errors.email}
                />
                <div className='relative mt-2'>
                  <InputComponent
                    type={showPassword ? 'text' : 'password'}
                    text='password'
                    placeholder='*******'
                    formik={formik}
                    value={formik.values.password}
                    autoComplete='current-password'
                    error={formik.touched.password && formik.errors.password}
                  />
                  <ToggleButtonComponent
                    showPassword={showPassword}
                    togglePasswordVisibility={togglePasswordVisibility}
                  />
                </div>

                {error.show && (
                  <div className=' text-start mb-2 text-red-500'>
                    {error.msg}
                  </div>
                )}
                {loader ? (
                  <LoaderButton />
                ) : (
                  <button
                    onClick={handleSubmit}
                    type='submit'
                    className='text-white bg-BluePurple font-medium h-10 w-full rounded-lg text-sm px-3 py-2.5  '
                  >
                    {ButtonText}
                  </button>
                )}
              </form>
            ) : (
              <form onSubmit={(e) => handleSubmit(e)}>
                <InputComponent
                  type='text'
                  text='first_name'
                  placeholder='Enter your first name *'
                  formik={formik}
                  value={formik.values.first_name}
                  autoComplete={'first_name'}
                  error={formik.touched.first_name && formik.errors.first_name}
                />
                <InputComponent
                  type='text'
                  text='last_name'
                  placeholder='Enter your last name *'
                  formik={formik}
                  value={formik.values.last_name}
                  autoComplete={'last_name'}
                  error={formik.touched.last_name && formik.errors.last_name}
                />
                <DateSelector
                  dobError={formik.touched.dob && formik.errors.dob}
                  setDob={setDob}
                />
                <InputComponent
                  type='email'
                  text='email'
                  placeholder='Enter your email *'
                  formik={formik}
                  value={formik.values.email}
                  autoComplete={'email'}
                  error={formik.touched.email && formik.errors.email}
                />
                <div
                  className={`relative  ${
                    formik.errors.password &&
                    formik.errors.password !== 'Password is required'
                      ? 'h-20'
                      : 'h-16'
                  }`}
                >
                  <InputComponent
                    type={showPassword ? 'text' : 'password'}
                    text='password'
                    placeholder='Enter your password *'
                    formik={formik}
                    value={formik.values.password}
                    autoComplete={'password'}
                    error={formik.touched.password && formik.errors.password}
                    helperText={
                      formik.values.password &&
                      !formik.touched.password &&
                      !/^(?=.*[A-Z]).{8,}$/.test(formik.values.password) &&
                      COMMON.PASSWORD_VALIDATION
                    }
                  />
                  <ToggleButtonComponent
                    showPassword={showPassword}
                    togglePasswordVisibility={togglePasswordVisibility}
                  />
                </div>
                <div className='relative mt-2'>
                  <InputComponent
                    type={showConfirmPassword ? 'text' : 'password'}
                    autoComplete={'confirm password'}
                    placeholder='Confirm your password *'
                    formik={formik}
                    value={formik.values.confirm_password}
                    text='confirm_password'
                    error={
                      formik.touched.confirm_password &&
                      formik.errors.confirm_password
                    }
                  />
                  <ToggleButtonComponent
                    showPassword={showConfirmPassword}
                    togglePasswordVisibility={toggleConfirmPasswordVisibility}
                  />
                </div>
                {loader ? (
                  <LoaderButton />
                ) : (
                  <button
                    onClick={handleSubmit}
                    type='submit'
                    className='text-white bg-BluePurple font-medium w-full rounded-lg text-sm px-3 py-2.5  '
                  >
                    {ButtonText}
                  </button>
                )}
                <div className='text-red-500 text-start my-1'>{error.msg}</div>
              </form>
            )}
            {login ? (
              <p className='flex justify-end items-end  text-BluePurple  hover:cursor-pointer'>
                <Link to='/forget-password'>Forgot Password?</Link>
              </p>
            ) : null}
          </div>
          <div className={styles.dividerText}>
            <span>OR</span>
          </div>

          <Box className='flex items-center justify-center my-6 '>
            <GoogleSignOn label='Sign in with Google' />
          </Box>
          <Box className='flex items-center justify-center my-6 '>
            <MicrosoftSignOn label='Sign in with Microsoft' />
          </Box>
          <p className={`${login ? 'my-7' : 'my-1'}`}>
            {CreateOrAlready}
            <span className='font-medium mx-2 hover:cursor-pointer text-BluePurple'>
              {login ? (
                <Link to='/sign-up'>{EndText}</Link>
              ) : (
                <Link to='/sign-in'>{EndText}</Link>
              )}
            </span>
          </p>
        </div>
      </div>
      <div className='block md:hidden pb-6 bg-lightGrey'>
        <FooterHelpLink linkStyle=' text-black' iconColor='#000' />
      </div>
    </div>
  );
};
export default LoginSignupComponent;
/* <p className='my-3'>OR</p>
          <ButtonComponent logo={GoogleLogo} label='Sign up with Google' />
          <ButtonComponent logo={FacebookLogo} label='Sign up with Facebook' />
          <ButtonComponent logo={AppleLogo} label='Sign up with Apple' /> */
