import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Threads from 'src/Api/Threads';
import { FirebaseAnalyticsEvent } from 'src/Firebase/analytics';
import { facebookEvent } from 'src/Utils/utils';

import { Auth } from '../../../Api';
import Avatar from '../../../Assets/images/v1/icons/sign-in-avatar.svg';
import { Auth as AuthConstants, COMMON } from '../../../Constants';
import LoginSignupComponent from '../../LoginSignupComponents';

function SignUpNew() {
  const { state, search } = useLocation();
  const history = useHistory();
  const default_email = state?.email;
  const [error, setError] = useState({ show: false, msg: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [, setCookie] = useCookies([AuthConstants.JWT_TOKEN]);
  const [loader, setLoader] = useState(false);
  const [dob, setDob] = useState({ day: '', month: '', year: '' });

  const refrerCode = search
    ? parseInt(new URLSearchParams(search).get('referrerId'), 10)
    : '';

  const handleSubmit = async (name, email, password) => {
    setError({ show: false, msg: '' });
    setLoader(true);
    try {
      const res = await Auth.signUp(
        name,
        email,
        'male',
        password,
        `${dob.day} ${dob.month}  ${dob.year}`,
        refrerCode,
      );
      const threadData = await Threads.getThread(res?.data?.data?.token);

      localStorage.setItem(
        'loginData',
        JSON.stringify({
          user: {
            ...res.data.data,
            thread_id: threadData?.data?.data?.id ?? null,
            content: '',
          },
        }),
      );
      setCookie(AuthConstants.JWT_TOKEN, res.data.data.token, {
        path: '/',
      });
      setCookie(AuthConstants.PROFILE_STATUS, res.data.data.profile_status, {
        path: '/',
      });
      setLoader(false);
      FirebaseAnalyticsEvent('sign_up');
      facebookEvent('CompleteRegistration');

      history.push('/home');
    } catch (e) {
      setLoader(false);
      setError({ show: true, msg: e.message });
    }
  };

  const signUpFormik = useFormik({
    initialValues: {
      password: '',
      email: default_email ?? '',
      first_name: '',
      last_name: '',
      confirm_password: '',
      dob: '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .min(2, 'First name cannot be less than 2 characters')
        .max(60, 'First name cannot be greater than 60 characters')
        .required('First name is required'),
      last_name: Yup.string()
        .min(2, 'Last name cannot be less than 2 characters')
        .max(60, 'Last name cannot be greater than 60 characters')
        .required('Last name is required'),
      password: Yup.string()
        .matches(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/,
          COMMON.PASSWORD_VALIDATION,
        )
        .max(20, COMMON.PASSWORD_MAX_LENGTH)
        .required('Password is required'),
      confirm_password: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      dob: Yup.string().required('Please enter a valid date of birth.'),
    }),
    onSubmit: async (values) => {
      await handleSubmit(
        `${values.first_name} ${values.last_name}`,
        values.email,
        values.password,
      );
    },
  });

  useEffect(() => {
    if (dob.day && dob.month && dob.year) {
      signUpFormik.handleChange({ target: { name: 'dob', value: 'abc' } });
    } else {
      signUpFormik.handleChange({ target: { name: 'dob', value: '' } });
    }
  }, [dob]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div>
      <LoginSignupComponent
        MainHeading='3X INCREASE IN YOUR CAREER'
        Paragraph='Achieve Your Professional Goals with Skilledscore. Skilled score helped me score a new job which I was dreaming of.'
        Avatar={Avatar}
        Heading='Create an account'
        SubHeading='Start your free trial.'
        ButtonText='Get Started'
        CreateOrAlready='Already have an account?'
        EndText='Login'
        formik={signUpFormik}
        error={error}
        showPassword={showPassword}
        showConfirmPassword={showConfirmPassword}
        togglePasswordVisibility={togglePasswordVisibility}
        toggleConfirmPasswordVisibility={toggleConfirmPasswordVisibility}
        loader={loader}
        setDob={setDob}
      />
    </div>
  );
}

export default SignUpNew;
