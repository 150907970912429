import React from 'react';

import howToImage1 from '../../../Assets/images/v1/how-to-img1.png';
import howToImage2 from '../../../Assets/images/v1/how-to-img2.png';
import howToImage3 from '../../../Assets/images/v1/how-to-img3.png';
import bottomRight from '../../../Assets/images/v1/icons/down-right.png';
import topRight from '../../../Assets/images/v1/icons/top-right.png';
import { SectionTitle, Text } from '../../LandingPage';

const HowToUseComponent = ({ src, title, description, arrow, isDown }) => {
  return (
    <div className='w-11/12 sm:w-6/12 md:w-1/4 px-2 flex flex-col relative mt-5'>
      <div className='hidden md:block'>
        {arrow ? (
          <div className={`absolute -right-24 ${isDown ? 'top-24' : 'top-10'}`}>
            <img src={arrow} className='w-32' />
          </div>
        ) : null}
      </div>

      <img src={src} className='w-40 h-40 mx-auto' />
      <Text className={'text-regular pb-4 text-center font-bold'}>{title}</Text>
      <Text className={'text-base text-center'}>{description}</Text>
    </div>
  );
};

const HowToUseSection = () => {
  return (
    <div className='pt-6 pb-16 bg-lightGrey px-4' id='how-to-use'>
      <div className='max-w-screen-2xl mx-auto '>
        <SectionTitle
          titleClass='!text-black'
          descriptionClass='!text-black'
          title='How to use SkilledScore?'
          description='A smarter way to find right skills and jobs'
        />
        <div className='flex flex-col justify-center items-center md:flex-row md:justify-around  '>
          <HowToUseComponent
            arrow={topRight}
            src={howToImage1}
            title='Create your profile and get your score'
            description='Unlock your full potential by transforming dreams into reality, one job at a time.'
          />
          <HowToUseComponent
            src={howToImage2}
            arrow={bottomRight}
            isDown
            title='Improve and share your skill score'
            description='Elevate Your Skill Score and Achieve Your Dream Job with SkilledScore: Where Aspiration Meets Opportunity!'
          />
          <HowToUseComponent
            src={howToImage3}
            title='Apply for suggested jobs'
            description='Unlock Your Dream Career by seamlessly applying for suggested jobs with SkilledScore - Your Path to Professional Success!'
          />
        </div>
      </div>
    </div>
  );
};

export default HowToUseSection;
