import React from 'react';
import PropTypes from 'prop-types';

import { Chip as MuiChip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(() => ({
  chipStyle: {
    marginRight: '5px',
    marginBottom: '5px',
  },
}));

const Chip = (props) => {
  const classes = useStyles();

  return (
    <MuiChip
      className={classes.chipStyle}
      label={props.label}
      onDelete={props.onDelete}
      variant='outlined'
      deleteIcon={<CancelIcon />}
    />
  );
};

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Chip;
