import { CareerSuggestion, JobSuggestion } from 'src/Assets/images/dashboard';

import FeaturesBottomRight from '../FeatureBottomright';
import FeaturesTopLeft from '../FeaturesTopLeft';

const FeaturesSection = () => {
  return (
    <div className='px-10 lg:px-16 md:px-28 font-[Karla-Regular] lg:mx-20'>
      <div className='max-w-screen-2xl mx-auto '>
        <div className='flex items-center flex-wrap lg:flex-nowrap'>
          <div>
            <h2 className='text-2xl md:text-4xl font-medium text-center md:text-left !font-[IBM-Ple-Sans]'>
              Job Suggestions
            </h2>
            <FeaturesTopLeft />
          </div>
          <div className='mt-8'>
            <img
              src={JobSuggestion}
              className='w-full h-full md:min-h-[400px] md:min-w-[400px]'
            />
          </div>
        </div>
        <div className='flex items-center lg:flex-nowrap flex-wrap-reverse'>
          <div className='mb-10 md:mb-2'>
            <img
              src={CareerSuggestion}
              className='w-full h-full md:min-h-[400px] md:min-w-[400px]'
            />
          </div>
          <div className='my-8 md:my-20 ml-4'>
            <h2 className='text-2xl md:text-4xl lg:ml-20 font-medium text-center md:text-left !font-[IBM-Ple-Sans]'>
              Analyze Career Path
            </h2>
            <FeaturesBottomRight />
          </div>
        </div>
      </div>
    </div>
  );
};
export default FeaturesSection;
