import logo from '../../Assets/images/v1/icons/logo.svg';

const FooterLeft = () => {
  return (
    <div className='mr-20 lg:mr-28 xl:mr-36'>
      <div className='mb-6 md:mb-0'>
        <a href='#' className='flex items-center pb-6'>
          <img src={logo} alt='Logo' className='w-full max-w-[250px]' />
        </a>
        <p className='text-white w-[20rem] text-base font-[Karla-Regular]'>
          Our Address:
        </p>
        <p className='text-white w-[20rem] text-lg font-[Karla-Regular]'>
          3577 Harlem Road
        </p>
        <p className='text-white w-[20rem] text-lg font-[Karla-Regular]'>
          Buffalo, NY 14225
        </p>
        <p className='text-white w-[20rem] text-base font-[Karla-Regular] mt-2'>
          Talk to Us:
        </p>
        <p className='text-white w-[20rem] text-lg font-[Karla-Regular]'>
          feedback@skilledscore.com
        </p>
        {/* <p className='text-white mt-2'>+92 332 2934447</p> */}
      </div>
    </div>
  );
};
export default FooterLeft;
